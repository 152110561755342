import * as React from "react";
// Customizable Area Start
import { Box, createTheme, Grid, ThemeProvider, Typography, withStyles } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SidebarNotificationWrapper from  '../../../notifications/src/Notifications.web'
import { ProfileIcon } from "../assets";

const theme = createTheme({
  palette: {
      primary: {
          main: "#0000ff",
          contrastText: "#fff"
      }
  }
});

// Customizable Area End

export class HPProfileView extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.showHpProfile()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
                <ThemeProvider theme={theme}>
                    <Box className={classes.leftHPWrapper}>
                        <SidebarNotificationWrapper {...this.props} hideNotification >
                            <Box className={classes.righHPtWrapper}>
                                <Box>
                                <Box className={classes.headerHPContainer}>
                                        <Box className={classes.orderHeading}>
                                            <ArrowBackIosIcon
                                                className={classes.calendarHPBackBtn}
                                                data-test-id="calendarHPBackBtn"
                                                onClick={this.handelPreviousPage}
                                            />
                                            <Typography variant="h4" className={classes.profileHPHeading}>View Profile</Typography>
                                        </Box>
                                    </Box>
                                    <Grid container>
                                        <Grid item xl={4} md={6} sm={6} xs={12}>
                                            <Box className={classes.topHPDetailsLeftContainer}>
                                                <Box className={classes.profileHPContainer}>
                                                    <img className={classes.avatarhp} src={this.state.profileData.patient_profile_image !== null ? this.state.profileData.patient_profile_image :ProfileIcon} />
                                                </Box>
                                                <Box className={classes.profileDetailsContainer}>
                                                    <Typography variant="h6" className={classes.userName}>{this.state.profileData.patient_profile_name}</Typography>
                                                </Box>
                                            </Box>
                                        <Box style={{borderBottom:"1px solid #DEDEDE", width:"300px",marginBottom:"25px",marginTop:"25px"}}></Box>
                                        </Grid>
                                        <Grid item xl={8} md={6} sm={6} xs={12}></Grid>
                                        <Grid item xl={6} md={8} sm={8} xs={12}>
                                            <Box>
                                                <Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Patient Id
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails} style={{textTransform:"lowercase"}}>
                                                            {this.state.profileData.patient_id}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Booking Id
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails} style={{textTransform:"lowercase"}}>
                                                            {this.state.profileData.id}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Age
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails} style={{textTransform:"lowercase"}}>
                                                            {this.state.profileData.age} y/o 
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Gender
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.profileData.gender}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Service Booked
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.profileData.service}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Date
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                        {this.state.profileData.date}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Time
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.profileData.start_time} - {this.state.profileData.end_time}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Consulation Fee
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            Rs. {this.state.profileData.amount}.00
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Status
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.profileData.status}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Payment Mode
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.profileData.payment_type}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
    leftHPWrapper: {
        overflowX: "hidden" as "hidden",
        height: "100%",
    },
    righHPtWrapper: {
        padding:"50px",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#fff #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
        '@media(max-width:600px)':{
            padding:'24px'
        }
    },
    headerHPContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    orderHeading: {
        display: "flex",
        alignItems: "center",
        marginBottom:"40px",
        justifyContent: "flex-start",
        gap: "25px",
    },
    profileHPHeading: {
        width: "100%",
        caretColor: "transparent",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    topHPDetailsLeftContainer:{
        display: "flex",
        alignItems: "center",
        gap:'24px',
        padding: "0px 0px 0px 0px",
        fontFamily: "SF Pro Text Regular",
    },
    profileHPContainer:{
       
    },
    calendarHPBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        padding: "13px 9px 13px 17px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        '@media(max-width:600px)':{
            padding:'11px 6px 11px 14px'
        }
    },
    avatarhp:{
        border: "1px solid #eeeaea",
        borderRadius: "50%",
        width: "155px",
        backgroundColor: "#eeeaea",
        height: "155px",
        '@media(max-width:600px)':{
            width:'90px',
            height:'90px'
        }
    },
    profileDetailsContainer:{
        
    },
    userName:{
        color: "#292929",
        fontSize: "24px",
        fontFamily: "SF Pro text Bold",
        caretColor: "transparent",
        textOverflow:'ellipsis',
        overflow:'hidden',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    }, 
    subContainer:{
        display: "flex",
        padding: "22px 0px 0px",
        fontFamily: "SF Pro Text Regular",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "start",
    },
    userDetailsHPLabel:{
        fontSize: "18px",
        "@media (max-width:1300px)": {
            width: "50% !important"
        },
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        width: "45%",
        textOverflow:'ellipsis',
        overflow:'hidden',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    userDetailsColon:{
        caretColor: "transparent",
        width: "8%",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    userDetails:{
        caretColor: "transparent",
        fontSize: "18px",
        
        fontFamily: "SF Pro Text Regular",
        color: "#717171",
        width: "75%",
        textTransform: "capitalize" as "capitalize",
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap' as const,
        '&:hover':{
            whiteSpace:'normal',
            overflow:'visible'
        },
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
}

export default withStyles(styles)(HPProfileView);
// Customizable Area End