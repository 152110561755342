// Customizable Area Start
import * as React from "react";
import { Grid, Box, withStyles, Typography, TextField, Button, FormHelperText, SvgIcon, Chip, ClickAwayListener } from "@material-ui/core";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { homecureTransparent, pexelsKarolina, patinetArrowBtn } from "./assets";
import PatientHealthDetails from "./PatientHealthDetails.web";
import Select from "react-select";
import { Values } from "./types";
import CloseIcon from '@material-ui/icons/Close';
import LocationView from "../../location/src/LocationView.web";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" }
];

// Customizable Area End

export class PatientRegisterDetails extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getLanguagesList()
    this.navigateTHealthDetails()
    await this.checkPatientExist();
    const patientData = await this.getPatientData();
    this.setPatientDetails(patientData)
  }

  errorMessage = (error: string | undefined, inputError: any) => {
    return (error ? <FormHelperText className={inputError}>{error}</FormHelperText> : null)
  };

  showErrorField = (error: string | undefined, classes: any) => {
    return (error ? classes.errorRegisterInputFields : classes.registerInputFields)
  };

  showSelectFieldError = (error: string | undefined, classes: any) => {
    return (error ? classes.customErrorSelectField : classes.customSelectField)
  };

  selectLanguageText = (selectedLanguages: any, classes: any) => {
    return (
      <>
        {selectedLanguages.length === 0 && (
          <span className={classes.selectLanguagePlaceholder}>Enter languages name</span>
        )}
      </>
    )
  }

  fontWeightStyle = (selectedValue: any, targetValue: string) => {
      const matchedLanguage = selectedValue.some((language: string) => language === targetValue);  
      if(matchedLanguage) return { fontWeight: 600, color: "black" }
      return { fontWeight: 500, color: "#808080" };
  };

  CustomSelectField = (selectBoxMargin:any,className: any, options: any, placeholder: string, testId: string, type: keyof Values, state: string | undefined) => {
   
    return (
      <Box className={selectBoxMargin}>
        <Select
          className={className}
          data-test-id={testId}
          options={options}
          placeholder={placeholder}
          value={state ? { value: state, label: state } : null}
          onChange={(event) => this.handleChange(type, event?.value)}
          isDisabled={this.state.patientExist}
          components={{
            IndicatorSeparator: () => null
          }} openMenuOnFocus styles={this.customStyle}
          isSearchable={false}
           />
      </Box>
    );
  }  

  getMarginTop = (selectedLanguages: any, gender: string | undefined) => {
    const margin = gender !== undefined && gender !== "" ? "13px" : "45px";
    if( gender !== undefined && gender !== "" && selectedLanguages > 2 ){
      return "45px"
    }else{
      return selectedLanguages > 2 ? "72px" : margin;
    }
  }

  familyValidation = (languages: any, gender: string | undefined) => {
    let margin = "17px";
    if(languages > 2 ){
      margin="11px"
    }
    return gender !== undefined && gender !== "" ? "29px" : margin;
  }

  patientLanguageContainer = (state:any,classes:any) => {
    return state.length>0 ? classes: "";
  }

  moveToNextStep = (step:number)=> {
    return step === 1 || step === 3
  }

  showDownArrow = (openLanguageDropdown:boolean,classes:any) =>{
    return !openLanguageDropdown? classes.patientLangDownArrowIcon : classes.patientLangUpArrowIcon
  }

  submitPatientsDetails = (patientExist:boolean) => {
    return patientExist ? this.moveNextPage : this.handleSubmit
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { errors, showGoogleMap, step } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        {!showGoogleMap ? (
          <>
            {this.moveToNextStep(step) ? (
              <Grid container>
                <Grid item xl={4} md={4} sm={12} xs={12} className={`${classes.gridContainer} ${classes.fixedGrid}`} >
                  <Box
                    component="div"
                    sx={{
                      height: "100%"
                    }}
                    style={{
                      backgroundImage: `url(${pexelsKarolina})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      padding: "10px",
                      caretColor:"transparent"
                    }}
                    className={classes.container}
                  >
                    <Box className={classes.homeCureLogosContainer}>
                      <img
                        src={homecureTransparent}
                        alt="HomeCure Logo"
                        className={classes.homecureLogos}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xl={8} md={8} sm={12} xs={12} className={classes.gridContainer}>
                  <Box className={classes.patientRegistrationContainer}>
                    <Box className={classes.patientLabelContainer}>
                      <Typography variant="h6" className={classes.registrationHeading}>
                        Register
                      </Typography>
                      <Box data-test-id="setLocation" style={{ width: "50%", textAlign: "right", textDecoration: "none" }}
                        onClick={this.openSetLocationPage}
                      >
                        <Typography variant="h6" data-test-id="setLocation" className={classes.setLocation} onClick={this.openSetLocationPage}>
                          Set Location
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="h6" className={classes.patientHeading}>
                      Let's get you all set up so you can verify and manage your account.
                    </Typography>
                    <Grid container spacing={4}>
                      <Grid item xl={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Name<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientName"
                            className={this.showErrorField(errors.name, classes)}
                            placeholder="Enter your name"
                            value={this.state.name}
                            onChange={(event) => this.handleChange("name", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.name, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            City<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientCity"
                            className={this.showErrorField(errors.city, classes)}
                            placeholder="Enter city"
                            value={this.state.city}
                            onChange={(event) => this.handleChange("city", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.city, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Pin Code<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientPincode"
                            className={this.showErrorField(errors.pincode, classes)}
                            placeholder="6 digit Pin Code"
                            value={this.state.pincode}
                            onChange={(event) => this.handleChange("pincode", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist, inputProps: { maxLength: 6 } }}
                          />
                          {this.errorMessage(errors.pincode, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Gender<span className={classes.required}>*</span>
                          </Typography>
                          {this.CustomSelectField(classes.selectBoxMargin,this.showSelectFieldError(errors.gender, classes), genderOptions, "Enter gender", "patientGender", "gender", this.state.gender)}
                          {this.errorMessage(errors.gender, classes.inputErrors)}
                        </Box>
                        <Box style={{ marginTop: this.getMarginTop(this.state.selectedLanguages.length,errors.gender) }}>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Family Member Name
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientFamilyMemberName"
                            className={this.showErrorField(errors.familyMemberName, classes)}
                            placeholder="Enter family member name"
                            value={this.state.familyMemberName}
                            onChange={(event) => this.handleChange("familyMemberName", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.familyMemberName, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Family Member Phone No.
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientFamilyPhoneNumber"
                            className={this.showErrorField(errors.familyMemberPhone, classes)}
                            placeholder="Enter phone number"
                            value={this.state.familyMemberPhone}
                            onChange={(event) => this.handleChange("familyMemberPhone", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist, inputProps: { maxLength: 10 }}}
                          />
                          {this.errorMessage(errors.familyMemberPhone, classes.inputErrors)}
                        </Box>
                        <Button
                          variant="contained"
                          data-test-id="submit"
                          color="primary"
                          className={classes.submitPatientProfileButton}
                          style={{ textTransform: "capitalize" }}
                          onClick={this.state.patientExist ? this.moveNextPage : this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileGrid}>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Address<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientAddress"
                            className={this.showErrorField(errors.address, classes)}
                            placeholder="Enter your address"
                            value={this.state.address}
                            onChange={(event) => this.handleChange("address", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.address, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            District<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientDistrict"
                            className={this.showErrorField(errors.district, classes)}
                            placeholder="Enter district"
                            value={this.state.district}
                            onChange={(event) => this.handleChange("district", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.district, classes.inputErrors)}
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Age<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientAge"
                            className={this.showErrorField(errors.age, classes)}
                            placeholder="Enter age"
                            value={this.state.age}
                            onChange={(event) => this.handleChange("age", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.age, classes.inputErrors)}
                        </Box>
                        <ClickAwayListener onClickAway={this.closeLanguageDropdown} data-test-id="CloseLanguageDropdown">
                          <Box data-test-id="language" id="language">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Languages Known
                          </Typography>
                          <Box className={classes.hpSelectCheckbox}>
                            <Box data-test-id="paientLanguage" className={classes.languagePatientBox} onClick={this.openLanguageDropdown}>
                              <Box className={this.patientLanguageContainer(this.state.selectedLanguages,classes.patientLanguageBox)}>
                                {this.state.selectedLanguages.map((languageId: any) => {
                                    const language = this.state.languagesList.find((language: any) => language.id === languageId);
                                    if (language) {
                                      return (
                                        <Chip
                                          data-test-id="removeLanguage"
                                          key={language.id}
                                          label={language.attributes?.name}
                                          onDelete={() => this.removeSelectedLanguage(language.id)}
                                          deleteIcon={<CloseIcon fontSize="small" />}
                                          className={classes.langIcon}
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                              </Box>
                              {this.selectLanguageText(this.state.selectedLanguages, classes)}
                              <img
                                className={this.showDownArrow(this.state.openLanguageDropdown,classes)}
                                src={patinetArrowBtn}
                                alt="DownAyahArrow"
                                style={{ marginLeft: 'auto' }}
                              />
                            </Box>
                            <>
                              {this.state.openLanguageDropdown && (
                                <Box className={classes.patientlangSelectFieldDropdown}>
                                  <Box className={classes.hpLangDropdownContent}>
                                    <Box data-test-id="selectPatientLanguage"  className={classes.patientangSelectFieldSize}>
                                      {this.state.languagesList.map((language: any, index: number) => (
                                        <Box key={index} data-test-id="selectLang" className={classes.patientSelectOption} onClick={() => this.handleLanguagesChange(language.id)} style={this.fontWeightStyle(this.state.selectedLanguages, language.id)}>
                                          {language.attributes.name}
                                        </Box>
                                      ))}
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                            </>
                          </Box>
                          <Typography variant="h6" className={classes.languageText}>
                            (Please select a maximum of 4 languages)
                          </Typography>
                          </Box>
                        </ClickAwayListener>  
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{marginTop:this.familyValidation(this.state.selectedLanguages.length,errors.gender),textAlign: "left" }}>
                            Family Member Relation
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="patientFamilyRelation"
                            className={this.showErrorField(errors.familyMemberRelation, classes)}
                            placeholder="Enter family member relation"
                            value={this.state.familyMemberRelation}
                            onChange={(event) => this.handleChange("familyMemberRelation", event.target.value.trimStart())}
                            InputProps={{ readOnly: this.state.patientExist }}
                          />
                          {this.errorMessage(errors.familyMemberRelation, classes.inputErrors)}
                        </Box>
                        <Button
                          data-test-id="submit"
                          variant="contained"
                          color="primary"
                          className={classes.registerMobileButtons}
                          style={{ textTransform: "capitalize" }}
                          onClick={this.submitPatientsDetails(this.state.patientExist)}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <>
                <PatientHealthDetails navigation={this.props.navigation} step={this.state.step} id={""} google={undefined} diseasesList={this.state.diseasesList} />
              </>
            )}
          </>
        ) : (<>
            <LocationView id="" navigation="undefined" setAddress={this.setAddressFromChild} closeSetLocationPage={this.closeSetLocationPage} setAddressFieldsByMap={this.setAddressFieldsByMap} showGoogleMap={this.state.showGoogleMap} longitude={this.state.longitude} latitude={this.state.latitude} />
        </>)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(PatientRegisterDetails);
// Customizable Area End
