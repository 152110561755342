import React from "react";
// Customizable Area Start
import Reservation2Controller, {configJSON, filters, Props } from "./Reservations2Controller";

import {
  Box,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  withStyles,
  CardContent,
  Chip,
  ClickAwayListener,
  TableRow,
  TableCell,
  Checkbox,
  SvgIcon,
} from "@material-ui/core";
import { searchIcons, NoData, DownArrow, UserProfile, filterIcons, docBanner, medic } from "./assets";
import { PositionValue, floatType, textTransform, FlexDirectionProperty } from "./types";
import '../../../web/src/fonts/stylesheet.css';
import {Star, Close} from '@material-ui/icons';
import Filter from "../../filteritems/src/Filter.web"
import { getStorageData } from "../../../framework/src/Utilities";
import { webStyles as styles } from "./Reservations2DoctorBooking.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});

const DoctorChecked = () => (<SvgIcon viewBox="0 0 18 18" fill="none" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" /><path  d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></SvgIcon>);

const UnDoctorChecked = () => (<SvgIcon fill="none" width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="15" height="15" rx="2.2" strokeWidth={'1.5'} opacity={'0.3'} stroke="#014866" /></SvgIcon>);


// Customizable Area End
export class Reservations2MedicBooking extends Reservation2Controller {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollContainerRef = React.createRef();
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState: any) {
    const { currentPage } = this.state;
    if (prevState.currentPage !== currentPage) {
      try {
        if (!this.state.loading && !this.state.isDataEmpty) {
          await this.getDoctorList();
        }
      } catch (error) {
        this.showAlert("Error fetching hospital data:", "Something went wrong");
      }
    }
  }

  medicOnScroll = () => {

    const getElement = this.scrollContainerRef.current;

    if (getElement && !this.state.loading) {
      const { clientHeight, scrollTop, scrollHeight } = getElement;
      if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - configJSON.TEN && this.state.doctorList?.length !== this.state.doctorCount) {
        this.setState(prevState => ({
          currentPage: Math.max(1, prevState.currentPage + 1),
          perPage: Math.max(10, prevState.perPage),
        }));
      }
      this.setState({ prevScrollTop: scrollTop });
    }
  };

  async componentDidMount() {
    let prevFilters= await getStorageData('hpFilters') as string;
    let parseFilters= JSON.parse(prevFilters);

    if (parseFilters) {
      this.setState((prevState) => ({
        ...prevState,
        selectedFilters: parseFilters,
      })); 
    }

    try {
      await Promise.all([this.getDoctorList()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }

    let serviceType = await getStorageData("serviceType");
    let redirectFromDashboard = await getStorageData("redirectFromDashboard");
    redirectFromDashboard = JSON.parse(redirectFromDashboard);

    serviceType = `${serviceType}s`
    this.setState({
      showDialog: redirectFromDashboard,
      serviceType: serviceType,
      bookingErrorMessage: `All ${(serviceType || "Medic personnals")} are filtered according to languages known by you.`
    })
  }

  async componentWillUnmount() {
    const getElement = document.getElementById("test");
    if (getElement) {
      getElement.removeEventListener('scroll', this.medicOnScroll);
    }
  }

  RenderBannersImage = () => {
    const {classes} = this.props;
    return (
      <Box className={classes.bannerImageWrapper}>
        <Box className={classes.gradient} ></Box>
      <img
        className={classes.docBanner}
        src={docBanner}
        alt="Banner"
      />
      <img src={medic} className={classes.medic} />
      </Box>
    )
  }

  RenderBannerDescription = (classes: any) => {
    return (
      <Box className={classes.bannerTextMainDiv}>
        <p className={classes.bannerTitle}>{this.state.serviceType}</p>
        <p className={classes.bannersDescription}>There are only two sorts of doctors those who practice with their brain.</p>
      </Box>
    )
  }

  renderMedicSlotFilter = () => {
    return (filters[3]).map((selectedSlotFilter: { name: string, value: string, time: string }, index: number) => {
      const filterTypeObjectSlot = this.state.selectedFilters.length > 0 && this.state.selectedFilters.find((filter: any) => filter.slot !== undefined);
      const checkForSlots = filterTypeObjectSlot ? filterTypeObjectSlot.slot.some((slot: {name:string}) => slot.name === selectedSlotFilter.name) : false;
  
      return (
        <TableRow 
          key={index} 
          data-test-id="slotTableRow" 
          className={this.props.classes.shiftSortOptionDiv}
        >
          <TableCell 
            data-test-id="slotType" 
            className={this.props.classes.shiftSortBox}
            style={this.getSelectedField(index, checkForSlots, false)} 
          >
            <span style={webStyles.timeSlotName}>{selectedSlotFilter.name}</span>
            <span style={webStyles.timeSlotTime}>{selectedSlotFilter.time}</span>
            
          </TableCell>
          <TableCell 
            style={this.getRowStyles(index, checkForSlots, false)}
            data-test-id="slotTableCell" 
          >
            <Checkbox 
              checked={checkForSlots} 
              icon={<UnDoctorChecked />} 
              data-test-id={`slot${index}`} 
              checkedIcon={<DoctorChecked />}
              className={this.props.classes.checkbox} 
              onChange={() => this.handleFilterCheckboxChange(index, selectedSlotFilter.value, "slot", selectedSlotFilter.name)} 
              
            />
          </TableCell>
        </TableRow>
      )
    })
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box style={styles.container}>
            <SidebarNotificationWrapper {...this.props}>
              <Box>
                <Box className={classes.availbleContainer}>
                  <Box style={styles.searchBarHolder}>
                    <Box style={{ width: "100%" }}>
                      <TextField
                        data-test-id="searchBarHP"
                        variant="outlined"
                        placeholder={`Search ${this.state.serviceType}...`}
                        onChange={(e) => this.setState({ searchDoctor: e.target.value })}
                        onKeyPress={(event) => this.searchDoctorByName(event)}
                        className={classes.searchDoctors}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={searchIcons}
                                alt="search"
                                style={styles.searchingIcon}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: "8px",
                            color: "#999999 !important",
                            fontFamily: "SF Pro Text Regular !important",
                            fontSize: "16px !important",
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      <Box className={this.state.showPopup ? classes.hpFilterIconSelected : classes.hpFilterIcon}>
                        <img
                          onClick={this.togglePopup}
                          src={filterIcons}
                          data-test-id="hpToggleButton"
                        />
                      </Box>
                      <Box>
                        {this.state.showPopup && (
                          <Filter
                            selectedFilters={this.state.selectedFilters}
                            navigation={this.props.navigation}
                            getHpList={this.getDoctorHpList}
                            id={""}
                            closePopup={this.togglePopup}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.bannersHolder}>
                    {this.RenderBannersImage()}
                    {this.RenderBannerDescription(classes)}
                  </Box>
                  <Box style={{ width: "100%" }}>
                    <Typography variant="h6" style={styles.upcommingBookingText}>
                      Available {this.state.serviceType}
                    </Typography>
                    <Typography variant="h6" style={styles.subDescText}>
                      Please choose date and time slots to see available {this.state.serviceType}
                    </Typography>
                    <Box className={classes.selectFilterHolder}>

                    <Box className={classes.selectParentContainer}>
                        <Box className={classes.customDatePickerName}>
                          <span className={classes.customDateInputName}>
                            <DatePicker
                              popperPlacement="bottom-start"
                              wrapperClassName={classes.datepickerClassesFullWidth}
                              calendarClassName={classes.datepickersClasses}
                              data-test-id="bookingdate"
                              popperModifiers={[
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 0],
                                  },
                                },
                                {
                                  name: "preventOverflow",
                                  options: {
                                    rootBoundary: "viewport",
                                    altAxis: true,
                                    tether: false,
                                  },
                                },
                              ]}
                              placeholderText="Select Date"
                              minDate={new Date()}
                              dateFormat="Select Date"
                              onChange={this.formatNewCustomDate}
                              className={classes.dateField}
                              value={this.state.date}
                            />
                          </span>
                        </Box>
                      </Box>
                      <Box className={classes.selectParentContainer}>
                      <Typography
                          onClick={this.handleShiftSelect}
                          className={classes.selectMenu}
                          data-test-id="selectSlot">Select Slot
                          <img
                            src={DownArrow}
                            alt="DownArrow"
                            style={styles.hpDownArrowIcon}
                          /></Typography>
                        {
                          this.state.shiftsAnchorEl && (
                            <ClickAwayListener
                              // data-test-id="handleSlotSelect"
                              onClickAway={() => this.handleShiftSelect()}
                            >
                              <Box
                                style={{ ...(this.state.shiftsAnchorEl ? styles.shiftsDropdownVisible : styles.dropdown) }}
                              >
                                {this.renderMedicSlotFilter()}
                              </Box>
                            </ClickAwayListener>
                          )
                        }
                      </Box>
                    </Box>

                    {this.extractNamedObjects(this.state?.selectedFilters,"medic")?.map((element: any, index: number) => {
                      return (
                        <Chip
                          key={index}
                          data-test-id={`removeFilter${index}`}
                          label={element.name}
                          onDelete={() => this.handleRemoveChip(element,"medic")}
                          deleteIcon={<Close style={{ width: "20px", color: "white" }} fontSize="small" />}
                          style={styles.chip}
                        />
                      )
                      })}
                    <div ref={this.scrollContainerRef} className={classes.scrollContainer} style={{ height: "50vh", overflow: this.state.doctorList?.length > 0 ? "auto" : "hidden", marginTop: "30px" }} data-test-id="scroll" onScroll={this.medicOnScroll}>
                      {this.state.doctorList?.length > 0 ? (
                        <Box id="doctorListBox" style={styles.availableDoctorList}>
                          {this.state.doctorList?.map((user: any, index: number) => (
                            <Box key={index} className={classes.doctorRoot}>
                              <Box data-test-id={`navigateToDoctorProfile${index}`} onClick={() => this.navigateToNurseProfile(user.id)} className={classes.flexBox}>
                                <img style={styles.doctorCover} src={user.attributes?.profile_image_url?.url || UserProfile} title={user.name} />
                                <Box style={styles.doctorDetails}>
                                  <CardContent style={styles.doctorContent}>
                                    <Box style={styles.doctorBox}>
                                      <Box>
                                        <Typography style={styles.doctorName} component="h5" variant="h5">
                                          {user.attributes?.name}
                                        </Typography>
                                      </Box>
                                      <Box style={styles.doctorRating}>
                                        <Star style={{ width: "14px", height: "13px" }} />
                                        <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                          <span style={styles.userRating}>{user?.rating || "4.2"}</span><span style={styles.userRatingOutOf}> / 5 </span>
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box style={styles.doctorBox}>
                                      <Box>
                                        <Box style={styles.doctorPriceShift}>
                                          <Typography style={styles.doctorFees} variant="h6">
                                          {this.state.sign}{user.attributes?.profile_type_specific_data?.consultation_with_service_fee}
                                          </Typography>
                                          <Typography style={styles.doctorShift} variant="subtitle1" color="textSecondary">
                                            / appointment
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box style={styles.doctorBox}>
                                      <Box>
                                        <Box style={styles.doctorPriceShift}>
                                          <Typography style={styles.doctorExperienceLabel} variant="h6">
                                            Experience:
                                          </Typography>
                                          <Typography style={styles.doctorExperience} variant="h6" color="textSecondary">
                                            {user.attributes?.experience} years
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </CardContent>
                                </Box>
                              </Box>
                              <Box className={classes.btnContainer}>
                                <Button
                                  variant="contained"
                                  data-test-id={`bookNow${index}`}
                                  color="primary"
                                  className={classes.BookNowBtn}
                                  onClick={() => this.goToBookDoctorNow(user.attributes?.shift_availabilities[0]?.healthcare_personnel_id)}
                                >
                                  Book Now
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )
                        :
                        (
                          <>
                            {
                              this.state.loading ? (
                                <>
                                  <Box data-test-id="doctorListLoaderTest" id="doctorListLoader" style={styles.loadingText}>Loading...</Box>
                                </>) : (
                                <>
                                  <Box data-test-id="noDataBoxDoctor" id="noDataBox" style={styles.noDataContainer}>
                                    <img
                                      src={NoData}
                                      width={60}
                                      height={60}
                                      style={styles.noDataIcons}
                                    />
                                    <Box style={styles.noDataText}>No results found</Box>
                                    <Box style={styles.subText} >Try adjusting or clearing your filtes to</Box>
                                    <Box style={styles.subText} >display better results.</Box>
                                  </Box>
                                </>)
                            }</>
                        )}

                    </div>
                    {this.state.doctorList?.length > 0 && this.state.loading && (
                      <Box id="doctorListLoader" style={styles.loadingText}>Loading...</Box>
                    )}
                  </Box>
                </Box>

              </Box>
            </SidebarNotificationWrapper>
          </Box>

          {this.state.showDialog &&<Dialog 
            classes={{ paper: classes.dialogpapers}} 
            style={{textAlign: "center"}}
            id="filterDialogBox" 
            disableScrollLock={true} 
            open={this.state.showDialog} 
          >
            <DialogContent style={{padding:0}}>
              <p className={classes.dialogTexts}>
                {this.state.bookingErrorMessage}
              </p>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Button
                color="primary"
                onClick={()=> this.handleDialogClose()}
                variant="contained"
                className={classes.dialogButtons}
                style={{textTransform: "capitalize"}}
                data-test-id="closePopUp"
              >
                Okay
              </Button>
            </DialogActions>

          </Dialog>}

        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  hpFilterIconSelected: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px 0px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  hpFilterIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px 0px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  selectParentContainer:{
    position:'relative' as const,
    '@media(max-width:600px)':{
      width:'100%'
    }
  },
  datepickerClassesFullWidth:{
    '@media(max-width:600px)':{
      '&.react-datepicker-wrapper':{width:'100%'},
    }
  },
  selectFilterHolder: {
    lineHeight: '26.8px',
    margin: "25px 0px 10px",
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    padding: "10px 0px",
    flexWrap:'wrap' as const
  },
  btnContainer:{
      
  },
  selectMenu: {
    width: '155px',
    height: '52px',
    border: '1px solid #808080',
    color: '#808080',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '21.48px',
    cursor: 'pointer',
    '@media(max-width:600px)':{
      width:'auto'
    }
  },
  availbleContainer: {
    padding: "40px 50px",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
    boxSizing:'border-box' as const,
    '@media(max-width:600px)':{
      padding:'24px'
    }
},
bannerImageWrapper:{
  position:"relative" as const,
  
},
gradient:{
  background: "linear-gradient(165deg, rgba(2, 126, 159, 0.5) 0%, #014866 100%)",
  height:'150px',
  borderRadius:'10px',
  '@media(min-width:600px)':{
    display:'none'
  }
},
docBanner:{
  width:'100%',position:"absolute" as const,bottom:'0',
  '@media(max-width:1200px)':{
    height:'150px'
  },
  '@media(max-width:991px)':{
    position:'relative' as const,
    height:'auto'
  },
  '@media(max-width:600px)':{
    display:'none'
  }
},
medic:{
  position:'relative' as const,zIndex:1,display:'block',marginLeft:'auto',
  '@media(max-width:991px)':{
    display:'none'
  }
},
  bannersHolder: {
    position:'relative' as const,
    margin: "0px 0 20px",
    width: "100%",
    borderRadius: "14px",
    objectFit: "contain" as "contain",
    '@media (max-width: 950px)': {
      marginBottom: 0,
    },
  },
  bannerTextMainDiv: {
    position: 'absolute' as PositionValue,
    left: '22px',
    color: 'white',
    zIndex: 1,
    top:'48%',
    '@media (max-width: 1280px)': {
      top: '58%',
    },
    '@media(max-width:1200px)':{
      top:'48%'
    },
    '@media(max-width:991px)':{
      top:"25%"
    },
    '@media(max-width:600px)':{
      top:'14%'
    }
  },
  bannersDescription: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0,
    letterSpacing: '-0.5142855048179626px',
    textAlign: 'left' as 'left',
    maxWidth: "80%",
    '@media (max-width: 1050px)': {
      maxWidth: "60%",
    },
    '@media(max-width:767px)':{
      fontSize:'16px',
      maxWidth:'none'
    }
  },
  bannerTitle: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '26.25px',
    marginTop: 0,
    marginBottom: "10px",
    letterSpacing: '-0.6285714507102966px',
    '@media(max-width:767px)':{
    
    }
  },
  availableDoctorList: {
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    gap: "25px",
    minHeight: "300px",
    maxHeight: "50vh",
    '@media (min-width: 1051px)': {
      width: "calc(100% - 100px) !important",
    },
    '@media (max-width: 1050px)': {
      width: '100% !important',
    },
  },
  searchDoctors: {
    width: "93.5%",
    margin: "15px 0px",
    "& .MuiInputBase-root": {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: "#292929 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
    },
    '&::placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
  },
  BookNowBtn: {
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    width: "170px",
    height: "52px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    float: "right" as floatType,
    '@media (max-width:1250px)': {
      width: "160px !important",
    },
    '@media(max-width:600px)':{
      marginRight:'0'
    }
  },
  flexBox: {
    display: 'flex',
    boxShadow: '0px 0px 40px #B3B3B31F',
    border: '1px solid #80808021',
    borderRadius: "10px",
    alignItems: 'center',
    padding: "5px 24px",
    cursor: 'pointer',
    maxWidth:'538px',
    '@media (min-width:991px)': {
      width: '100%',
    },
    '@media(max-width:991px)':{
      width:"100%"
    },
    '@media(max-width:600px)':{
      width:'100%',
      maxWidth:'250px',
      flexDirection:'column'
    }
  },
  doctorRoot: {
    display: 'flex',    
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    
    gap: "50px",
    flexWrap:'wrap' as const,
    '@media (max-width:991px)': {
      flexWrap:'nowrap'
    },
    '@media (max-width:600px)': {
      flexDirection:'column',
      justifyContent:'center'
    },
  },
  scrollContainer: {
    "-ms-overflow-style": "none",
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as const,
    "&& ::-webkit-scrollbar": {
      display: "none"
    },
  },
  dialogpapers: {
    borderRadius: "20px",
    maxWidth: "450px",
    padding: "10px 30px 50px",
    paddingBottom:'30px',
  },
  dialogTexts: {
    fontSize: "20px",
    color: "#383838",
    fontWeight: 500,
    fontFamily: "SF Pro Text Medium",
    lineHeight: "26px",
    caretColor: "transparent !important",
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  dialogButtons: {
    width: "100%",
    height: "65px",
    backgroundColor: "#014866",
    border: "none",
    color: "#fff",
    borderRadius: "10px",
    cursor: "pointer",
    padding: "10px",
    fontFamily: "SF Pro Text Bold",
    fontSize: "18px",
    fontWeight: 600,
    margin: "20px auto 10px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    caretColor: "transparent",
    '@media(max-width:600px)':{
      margin:"0"
    }
  },
  customDateInputName: {
    width: "187px",
    borderRadius: "8px",
    "&:focus": {
      border: "1px solid blue",
      cursor:'pointer',
    },
    "& ::-webkit-input-placeholder": {
      color: "#565454",
    },
    "& input": {
      color: "#808080",
      backgroundPosition: "calc(100% - 20px)",
      backgroundSize: "22px",
      fontSize: "18px",
      width: "187px",
      height: "55px",
      paddingLeft:'13px',
      border: "1px solid #808080",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      caretColor: "transparent",
      cursor:'pointer',
      "&::placeholder": {
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        opacity: 1,
        color: "#808080 !important",
      },
      fontFamily: "SF Pro Text Regular",
      background: `url(${DownArrow}) right / contain no-repeat`,
      '@media(max-width:600px)':{
      width:"100%"
    }
    },
    '@media(max-width:600px)':{
      width:"100%"
    }
  },
  timeSlotName:{
    fontFamily: 'SF Pro Text Medium',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21.48px',
    color: '#292929'
  },
  timeSlotTime:{
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    color: '#717171'
  },
  shiftSortOptionDiv:{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    '&:last-of-type': {
      borderBottom: "none !important",
      marginBottom: "5px"
    },
    borderBottom: "1px solid #f2f2f2",
    '&:first-of-type': {
      marginTop: "5px"
    },
  },
  shiftSortBox: {
    display: 'flex', 
    flexDirection: 'column' as FlexDirectionProperty, 
    alignItems: 'flex-start', 
    gap: '5px'
  },
  checkbox: {
    transform: "translateX(-10px)",
    "& .MuiCheckbox-colorSecondary .Mui-checked":{
      color: "white !important",
    },
    padding: "5px 25px 5px 0px",
    color: "white !important",
  },
  datepickersClasses:{
    "& .react-datepickerClass-wrapper":{display: "flex !important",},
    "& .react-datetime-picker__wrapper":{ border: "none"},
    "& .react-datepickerClass__month":{ margin: "0px !important"},
    "& .react-datepickerClass__day-name":{
      width: "1.3rem !important", height: "1rem !important"
    },
    "& .react-datepickerClass-popper":{transform: "translate(90px, 209px) !important"},
    "& .react-datepickerClass__month-container":{
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day":{
      height: "1rem !important", width: "1.3rem !important",
    },
    "& .react-datepickerClass__day-names":{
      marginBottom: "-1px !important", caretColor: "transparent"
    },
    "& .react-datepickerClass__day--keyboard-selected":{
      color: "#000 !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },
    "& .react-datepickerClass__day:hover":{
      color: "#ccc !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },
    "& .react-datepickerClass__day--today":{
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
      fontWeight: "bold !important",
      caretColor: "transparent",
    }, 
    "& .react-datepickerClass__navigation":{
      margin: "5px -2px !important",
      caretColor: "transparent",
      height: "22px !important",
      width: "22px !important",
    },
  },
};

export default withStyles(webStyles)(Reservations2MedicBooking);
// Customizable Area End

