import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {
}

interface SS {
    id: number;
}

export default class VideoCallManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
      ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
}