import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  withStyles,
  Dialog,
  DialogContent,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles } from "./styles/fileattachment";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// Customizable Area End

import FileattachmentController, {
  Props,
  configJSON,
} from "./FileattachmentController";

export class Fileattachment extends FileattachmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getdocumentList();
  }

  async componentDidUpdate(prevProps: Props) {
    // Check if docType has changed
    if (prevProps.docType !== this.props.docType) {
      await this.getdocumentList();
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, docType } = this.props;
    const documentType = docType === "upload" ? "Prescription" : "Scans & Reports";
    // Customizable Area End 
    return (
      // Customizable Area Start
      <>
        <Dialog
          open={this.props.openDialog && !this.state.openPreview}
          classes={{ paper: classes.dialogPaper }}
        >
          <Box className={classes.header}>
            <Typography className={classes.title}>View {documentType}</Typography>
            <IconButton
              data-test-id="closeDialog"
              onClick={this.closeDialog}
              className={classes.closeBtnIcon}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {this.state.fileDetails.length > 0 ? (
          <>
          <DialogContent className={classes.content}>
              {this.state.fileDetails.map((item: any, index: number) => (
                <Box key={index} className={classes.docList}>
                  <Box className={classes.container}>
                    <Box className={classes.blockOne}>
                      <img
                        alt="Profile"
                        className={classes.avatar}
                        src={item.profileImage}
                      />
                    </Box>
                    <Box style={{display:'flex',alignItems:"center",justifyContent:'space-between',width:'100%'}}>
                    <Box className={classes.blockTwo}>
                      <Typography variant="subtitle1" className={classes.userName}>
                        {item.userName}
                      </Typography>
                      <Typography variant="caption" className={classes.status}>
                        Previously Uploaded
                      </Typography>
                    </Box>
                    <Box className={classes.blockThree}>
                      <Typography variant="subtitle1" className={classes.day}>
                        {item.days}
                      </Typography>
                      <Typography variant="caption" className={classes.time}>
                        {item.formattedDateWithTime}
                      </Typography>
                    </Box>
                    </Box>
                  </Box>
                  <Button data-test-id={`openPrescriptionPrevirew${index}`} variant="outlined" className={classes.button} onClick={() => this.fetchDoc(item.file, item.formattedDate)}>
                    Open File
                  </Button>
                </Box>
              ))}
          </DialogContent>
          </>
          ):(
          <>
            <Typography variant="h4" className={classes.noDatafound}>
              File not found.
            </Typography>
          </>
          )}
        </Dialog>
        <Dialog
          open={this.state.openPreview}
          classes={{ paper: classes.dialogPrescriptionPaper }}
        >
          <Box className={classes.dialogPrescriptionHeader}>
            <Box className={classes.presBackButtons} onClick={this.closePerview}>
              <ArrowBackIosIcon data-test-id="backBtn" className={classes.arrowIcon} onClick={this.closePerview} />
            </Box>
            <Typography className={classes.docTitle}>Previous {documentType}</Typography>
            <Typography className={classes.date}>{this.state.date}</Typography>
          </Box>
          <DialogContent style={{padding:'0'}}>
          {
            this.state.fileType == 'pdf' ? 
            (
            <iframe 
              src={this.state.documentURL} 
              width="100%" 
              height="600px"
              style={{ border: 'none' }}
            />
          )
          :
          (
            <img
              className={classes.docCover}
              alt="document"
              src={this.state.documentURL}
            />
          )
          }
          </DialogContent>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(Fileattachment);
// Customizable Area End
