import { Box, Divider, IconButton, Typography, withStyles } from "@material-ui/core";
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Edit, Notification, Setting } from "./assets";
import React from 'react'
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import Loader from "../../../components/src/Loader.web";
import { getStorageData } from "../../../framework/src/Utilities";

export class HospitalProfileWrapper extends CustomisableUserProfilesController {

    async componentDidMount() {
        const userType = await getStorageData('userType');
        this.setState({ userType })
    }

    renderPopover = () => {
        const { classes } = this.props;
        const boundingRect = this.state.settingPopoverEl?.getBoundingClientRect();
        return this.state.settingPopoverEl && <>
            <Box data-test-id='popover' className={classes.settingPopoverWrapper}
                style={{
                    ...webStyle.filterModalWrapper,
                    top: `${boundingRect && boundingRect.bottom + window.scrollY + 20}px`,
                    left: `${boundingRect && boundingRect.left}px`,
                    transform: 'translate(-90%,0)'
                }}
            >
                <Box className={classes.settingsOuterWrapper} >
                    <Box className={classes.settingsInnerWrapper}>
                        <Typography className={classes.settingsTitle}>{configJSON.settingsItems.Settings} </Typography>
                        {this.state.userType === 'hospital' && <>
                            <Typography className={classes.settingsItem} data-test-id="goto-manage" onClick={this.navigateFromSettings(configJSON.settingsPageName.manage)} >{configJSON.settingsItems.Manage}</Typography>
                            <Divider className={classes.divider} />
                        </>}
                        <div className={classes.hiddenUp} >
                            <Typography className={classes.settingsItem} data-test-id='goto-edit-profile' onClick={() => this.openHospitalEditProfiles(this.state.userType)} >Edit Profile</Typography>
                            <Divider className={classes.divider} />
                            <Typography className={classes.settingsItem} data-test-id='goto-notification' onClick={() => this.goToBlock('Dashboard')}>Notifications</Typography>
                            <Divider className={classes.divider} />
                        </div>
                        <Typography className={classes.settingsItem} data-test-id='goto-about' onClick={this.navigateFromSettings(configJSON.settingsPageName.aboutUs)} >{configJSON.settingsItems.AboutUs}</Typography>
                        <Divider className={classes.divider} />
                        <Typography className={classes.settingsItem} data-test-id='termsandcondition' onClick={this.navigateFromSettings(configJSON.settingsPageName.termsCondition)}>{configJSON.settingsItems.TermsConditions}</Typography>
                        <Divider className={classes.divider} />
                        <Typography className={classes.settingsItem} data-test-id='privacy-policy' onClick={this.navigateFromSettings(configJSON.settingsPageName.privacyPolicy)}>{configJSON.settingsItems.PrivacyPolicy}</Typography>
                        <Divider className={classes.divider} />
                        <Typography className={classes.settingsItem} data-test-id='help' onClick={this.navigateFromSettings(configJSON.settingsPageName.help)} >{configJSON.settingsItems.Help}</Typography>
                        <Box className={classes.actionButtonWrapper} >
                            <CustomStyledButton
                                isSecondary
                                data-test-id="deactivate"
                                onClick={this.navigateFromSettings('deactivate')}
                                label={configJSON.ButtonLabel.DeactivateAccount}
                            />
                            <CustomStyledButton
                                data-test-id="logout"
                                onClick={this.handleLogout}
                                label={configJSON.ButtonLabel.Logout}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    }

    render() {
        const { classes } = this.props;
        return <>
            <Box>
                <div className={classes.pageTitleWrapper}>
                    {this.props.profileBackBtn && <IconButton style={{ padding: '0' }} data-test-id="backBtn" onClick={() => this.goToBlock(this.getBackPagesPath(this.state.userType))}>
                        <div className={classes.hospitalEditBackButtonsWrapper}>
                            <ArrowBackIosIcon className={classes.arrowIconWrapper} />
                        </div>
                    </IconButton>}
                    <Typography className={classes.profileHospitalHeadingWrapper}>
                        {this.props.profilePageTitle}
                    </Typography>
                </div>
                <div className={classes.profileBarWrapper}>
                    <div className={classes.hospitalProfileViewContainerWrapper}>
                        <div className={classes.profileSectionWrapper} >
                            <div className={classes.editIconsConatinerWrapper}>
                                <div>
                                    <img className={classes.avatarEditWrapper} src={this.props.profilePicture ?? this.state.profilePicture} />
                                    {this.props.openProfileSelectModal && <img
                                        data-test-id="editProfile"
                                        className={classes.avatarEditIconWrapper}
                                        src={Edit}
                                        onClick={this.props.openProfileSelectModal}
                                    />}
                                </div>

                                {!this.props.hideProfileActionBtns && <Box style={webStyle.iconsContainer}>
                                    <div className={classes.hiddenDown} >
                                        <IconButton data-test-id="editProfile-page" onClick={() => this.openHospitalEditProfiles(this.state.userType)}>
                                            <img style={webStyle.iconStyle} src={Edit} />
                                        </IconButton>
                                    </div>
                                    <div className={classes.hiddenDown} >
                                        <IconButton data-test-id="notification" onClick={() => this.goToBlock('Dashboard')}>
                                            <img style={webStyle.iconStyle} src={Notification} />
                                        </IconButton>
                                    </div>
                                    <Box >
                                        {this.state.settingPopoverEl && <div className={classes.overlay} data-test-id='overlay' onClick={this.closeSettingsPopover}></div>}
                                        <IconButton data-test-id="open-settings" onClick={this.handleOpenSettings} style={this.state.settingPopoverEl ? webStyle.activeIcon : webStyle.settingIcon}>
                                            <img style={webStyle.iconStyle} src={Setting} />
                                        </IconButton>
                                    </Box>
                                </Box>}
                            </div>
                        </div>
                    </div>
                    {this.renderPopover()}
                </div>
                <>
                    {this.props.children}
                </>
                <Loader loading={this.state.isLoading} />
            </Box>
        </>
    }
}

const webStyle = {
    hiddenUp: {
        display: "none",
        '@media(max-width:991px)': {
            backgroundColor: 'white',
            borderRadius: '4px',
            display: 'block'
        }
    },
    hiddenDown: {
        '@media(max-width:991px)': {
            display: "none"
        }
    },
    settingPopoverWrapper: {
       
    },
    filterModalWrapper: {
        position: "absolute" as const,
        zIndex: 200,

        backgroundColor: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed' as const,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        zIndex: 100
    },
    paper: {
        borderRadius: '25px'
    },
    backDrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    divider: {
        backgroundColor: "#F0F0F0"
    },
    settingsTitle: {
        '&.MuiTypography-body1': {
            color: "#292929",
            fontSize: '24px',
            fontFamily: 'SF Pro Text Bold',
            textAlign: 'center' as const,
            marginBottom: '14px',
            '@media(max-width:991px)':{
                fontSize:'20px'
            },
            '@media(max-width:600px)':{
                fontSize:'18px'
            }
        }
    },
    settingsItem: {
        '&.MuiTypography-body1': {
            color: '#292929',
            fontSize: '20px',
            fontFamily: "SF Pro Text Regular",
            textAlign: 'center' as const,
            lineHeight: '64px',
            cursor: 'pointer',
            '@media(max-width:991px)': {
                fontSize: '18px',
                lineHeight:'54px'
            },
            '@media(max-width:600px)': {
                fontSize: '16px',
                lineHeight:'44px'
            }
        }
    },
    settingsOuterWrapper: {
        width: '390px',
        background: 'white',
        '@media(max-width:600px)': {
            width: '300px'
        }
    },

    settingsInnerWrapper: {
        border: '1px solid #EDEDED',
        padding: '32px 24px',
        borderRadius: '25px',
        boxShadow: "0px 2px 24px 0px #2C2C2C0A"
    },

    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'column' as const,
        gridRowGap: '24px',
        marginTop: '42px',
        '@media(max-width:600px)':{
            marginTop:'20px'
        }
    },

    secondaryBtn: {
        margin: '0 auto 0',
        width: '100%',
        maxWidth: '300px',
        border: "1px solid #014866",
        height: '56px',
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        borderRadius: '8px',
        backgroundColor: 'white',
        textTransform: 'capitalize' as const
    },

    primaryBtn: {
        margin: '0 auto 0',
        width: '100%',
        maxWidth: '300px',
        height: '56px',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        borderRadius: '8px',
        backgroundColor: '#014866',
        color: 'white',
        textTransform: 'capitalize' as const
    },

    profileSectionWrapper: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative" as const,
        background: "#dce7fa;",
        zIndex: 1,
        height: "130px"
    },
    iconsContainer: {
        display: "flex",
        alignItems: 'center',
        padding: "32px 0px",
        gap: "40px",
        position: 'relative' as const
    },
    iconStyle: {
        width: "30px",
        height: "30px",
        cursor: "pointer"
    },
    avatarEditWrapper: {
        borderRadius: "50%",
        width: "170px",
        height: "170px",
        border: "1px solid #eeeaea",
        backgroundColor: "#eeeaea",
        zIndex: 1,
        cursor: "pointer",
        '@media(max-width:991px)': {
            width: '120px',
            height: '120px'
        },
        '@media(max-width:600px)': {
            width: '90px',
            height: '90px'
        }
    },
    editIconsConatinerWrapper: {
        display: "flex !important",
        justifyContent: "space-between !important",
        padding: "30px 140px 0 60px",
        position: "relative" as const,
        background: "#dce7fa",
        zIndex: 1,
        height: "100%",
        boxSizing: 'border-box' as const,
        width: "100%",
        '@media(max-width:960px)':{
            padding:'30px 70px 0 60px'
        },
        '@media(max-width:600px)': {
            padding: '0px  30px 0 30px',
            alignItems: 'center'
        }
    },
    hospitalProfileViewContainerWrapper: {
        position: "relative" as const,
        width: '100%'
    },
    profileHospitalHeadingWrapper: {
        '&.MuiTypography-root': {
            width: "100%",
            fontWeight: 600,
            fontSize: "28px",
            fontFamily: "SF Pro Text Bold",
            caretColor: "transparent",
            '@media(max-width:600px)': {
                fontSize: '18px'
            }
        }
    },
    hospitalEditBackButtonsWrapper: {
        display: "flex",
        alignItems: "center",
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        border: "1px solid #E9E9E9",
        padding: "10px",
        justifyContent: "center",
        borderRadius: "8px",
        boxSizing: 'border-box' as const
    },
    arrowIconWrapper: {
        height: "16px",
        caretColor: "white",
    },
    avatarEditIconWrapper: {
        top: 0,
        left: "188px",
        width: "20px",
        height: "20px",
        zIndex: 1,
        position: "absolute" as const,
        marginTop: "52px",
        background: "white",
        padding: "10px",
        borderRadius: "18px",
        cursor: "pointer",
        '@media(max-width:991px)': {
            left: '150px',
            marginTop: '36px'
        },
        '@media(max-width:600px)': {
            left: '94px',
            marginTop: '16px'
        }
    },
    pageTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '140px',
        paddingLeft: '60px',
        gridColumnGap: '30px',
        '@media(max-width:600px)': {
            height: '100px',
            paddingLeft: '24px',
            gridColumnGap: '15px'
        }
    },
    profileBarWrapper: {
        width: "100%"
    },
    activeIcon: {
        backgroundColor: 'white',
        padding: '8px',
        borderRadius: '10px',
        zIndex: 200,
        width: '40px',
        height: '40px',
    },
    settingIcon: {
        borderRadius: '8px',
        width: '40px',
        height: '40px',
        zIndex: 200
    },
}


export default withStyles(webStyle)(HospitalProfileWrapper)