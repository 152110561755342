import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  createTheme,
} from "@material-ui/core";
import DashboardController, { configJSON, Props } from "./DashboardController";
import { Position, objectFitType, textAlignType } from './types'
import { SOSImage, Homevisit, Hospitalvisit, FilterIcon, ProfileIcon } from "./assets"
import DashboardView from "./DashboardView.web";
import AdvanceSearch from "./AdvanceSearch.web";
import Loader from "../../../components/src/Loader.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0000ff',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0000ff',
        },
        '& input': {
          color: '#292929',
          fontSize: '16px',
          cursor: 'pointer',
          fontFamily: 'SF Pro Text Regular',
          '&::placeholder': {
            opacity: 1,
            fontSize: '16px',
            fontFamily: 'SF Pro Text Regular',
            color: '#808080',
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: '#0000ff',
        },
      },
    },
  },
});

// Customizable Area End
class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  showPrescription = (openPrescription: boolean, precriptions: any) => {
    return (
      <>
        {precriptions.length > 0 ? (
          <>
            <style>
              {`
                .MuiPaper-root {
                  min-width: 630px !important;
                  border-radius: 10px,
                  background-color: red !important;
             `}
            </style>
            <Dialog
              open={openPrescription}
              onClose={this.hidePrescription}
            >
              <Box style={webStyle.header}>
                <Typography style={webStyle.title}>View Prescription</Typography>
                <IconButton
                  data-test-id="closeDialog"
                  style={webStyle.closeBtnIcon}
                  onClick={this.hidePrescription}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <>
                <DialogContent style={webStyle.content}>
                  {precriptions.map((data: any, index: number) => (
                    <Box style={webStyle.docList} key={index}>
                      <Box style={webStyle.container}>
                        <Box style={webStyle.blockOne}>
                          <img
                            alt="Profile"
                            style={webStyle.avatar}
                            src={ProfileIcon}
                          />
                        </Box>
                        <Box style={webStyle.blockTwo}>
                          <Typography variant="subtitle1" style={webStyle.userName}>
                            {data.name}
                          </Typography>
                          <Typography variant="caption" style={webStyle.status}>
                            {data.profile_type === 'patient' ? 'Previously Uploaded' : 'Completed your prescription'}
                          </Typography>
                        </Box>
                        <Box style={webStyle.blockThree}>
                          <Typography variant="subtitle1" style={webStyle.day}>
                            {data.day}
                          </Typography>
                          <Typography variant="caption" style={webStyle.time}>
                            {this.formatTime(data.time)}
                          </Typography>
                        </Box>
                      </Box>
                      <Button variant="outlined" style={webStyle.button} onClick={() => this.showFile(data.prescription_id,data.profile_type)}>
                        Open File
                      </Button>
                    </Box>
                  ))}
                </DialogContent>
              </>
            </Dialog>
          </>
        ) : (
          <>
            <Typography>Data not found</Typography>
          </>
        )}
      </>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Loader loading={this.state.isLoading} />
          <SidebarNotificationWrapper {...this.props}>
            <Box style={webStyle.hospitalDashboardWrapper}>
              {this.state.userType === configJSON.userType.Patient && (
                <>
                  <Box>
                    <Box style={webStyle.searchBarContainr}>
                      <AdvanceSearch data-test-id='advance-search' {...this.props} />
                      <img
                        style={webStyle.sosImage}
                        src={SOSImage}
                      />
                    </Box>
                    <Box>
                      <img
                        style={webStyle.bannerImage}
                        src={this.state.banner}
                      />
                    </Box>
                    <Box style={webStyle.ServiceBtn}>
                      <Box style={webStyle.serviceBox}>
                        <Typography variant="h6" style={webStyle.serviceText}>
                          Services
                        </Typography>
                      </Box>
                      <Box style={webStyle.serviceBoxTwo}>
                        <Typography variant="h6" style={webStyle.Services} data-test-id="clickToPrescription" onClick={this.handelPrescription}>
                          View Prescriptions
                        </Typography>
                        <Typography variant="h6" style={webStyle.Services}>
                          Book Lab Tests
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                      {this.state.data.map((item: { image: string | undefined; name: string | undefined }, index: React.Key | undefined) => (
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                          <Box data-test-id={`clickToService${index}`} style={{
                            ...webStyle.serviceContainer, backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${item.image})`
                          }} onClick={() => this.openService(item.name)}>
                            <Typography style={webStyle.serviceName}>{item.name}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
              )}
              {this.state.userType === configJSON.userType.HealthcarePersonnel && (
                <DashboardView id={""} currentPage={this.state.currentPage} navigation={undefined} />
              )}

              {this.state.userType !== configJSON.userType.Patient && this.state.userType !== configJSON.userType.HealthcarePersonnel && (
                <>
                  <Box sx={webStyle.searchFilterWrapper}>
                    <AdvanceSearch data-test-id='advance-search' {...this.props} />
                    <IconButton>
                      <img src={FilterIcon} alt="filter_icon" />
                    </IconButton>
                  </Box>
                  <Box sx={webStyle.hospitalDetailsWrapper}>
                    <Box sx={webStyle.hospitalInfoWrapper}>
                      <Box sx={webStyle.apointmentWrapper}>
                        <Typography style={webStyle.numberOfAppointment}>{this.state.hospitalDashboardData?.data.total_reservations}</Typography>
                        <Typography style={webStyle.appointmentDataText}>{configJSON.HospitalDashboard.TOTAL_APPOINTMENTS}</Typography>
                      </Box>
                      <Box sx={webStyle.apointmentWrapper}>
                        <Typography style={webStyle.numberOfAppointment}>{this.state.hospitalDashboardData?.data.todays_reservations}</Typography>
                        <Typography style={webStyle.appointmentDataText}>{configJSON.HospitalDashboard.TODAYS_APPOINTMENTS}</Typography>
                      </Box>
                      <Box sx={webStyle.apointmentWrapper}>
                        <Typography style={webStyle.numberOfAppointment}>{this.state.hospitalDashboardData?.data.total_beds_booked}</Typography>
                        <Typography style={webStyle.appointmentDataText}>{configJSON.HospitalDashboard.TOTAL_BED_BOOKED}</Typography>
                      </Box>
                    </Box>
                    <Box sx={webStyle.bedInfoWrapper}>
                      <Typography style={webStyle.availableBeds} >{configJSON.HospitalDashboard.AVAILABLE_BEDS}</Typography>
                      <Box sx={webStyle.bedInfoCardWrapper} >
                        {
                          this.state.hospitalDashboardData && this.state.hospitalDashboardData?.data?.available_bed_types.length > 0 ? this.state.hospitalDashboardData?.data.available_bed_types.map((bed) => {
                            return <Box sx={webStyle.bedInfoCard}>
                              <Typography style={webStyle.numberOfAvailableBeds}>{bed.available_beds} <span style={webStyle.availableOutOf}>/ <span style={webStyle.availableOutofFontSize} > {bed.total_beds} </span> </span></Typography>
                              <Divider style={webStyle.divider} />
                              <Typography style={webStyle.bedTypeInfo}>{bed.bed_name}</Typography>
                            </Box>
                          })
                            : <Typography style={{ fontFamily: "SF Pro text Regular" }}>{configJSON.NoBedsAvailable}</Typography>
                        }
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </SidebarNotificationWrapper>
          <style>
            {`
                .MuiDialog-paper {
                  border-radius: 15px;
                }
              `}
          </style>
          <Dialog open={!!this.state.showPopup} data-test-id="hidePopup" onClose={this.hidePopup}>
            <Box>
              <Box style={{ padding: "35px 45px" }}>
                <p style={{
                  fontSize: "20px",
                  color: "#292929",
                  margin: "auto",
                  textAlign: "center" as "center",
                  lineHeight: "32px",
                  caretColor: "transparent",
                  fontFamily: "SF Pro Text Medium",
                }}>All Doctors are filtered according to</p>
                <p style={{
                  fontSize: "20px",
                  color: "#292929",
                  margin: "auto",
                  textAlign: "center" as "center",
                  lineHeight: "32px",
                  caretColor: "transparent",
                  fontFamily: "SF Pro Text Medium",
                }}>languages known by you.</p>
                <Button style={{
                  display: "flex",
                  borderRadius: "8px",
                  justifyContent: "center",
                  width: "100%",
                  height: "56px",
                  backgroundColor: "#014866",
                  color: "#fff",
                  border: "none",
                  padding: "16px",
                  cursor: "pointer",
                  fontFamily: "SF Pro Text Bold",
                  fontSize: "18px",
                  margin: "40px auto 15px",
                  textTransform: "capitalize" as "capitalize"
                }}
                  data-test-id="selectConsultationType"
                  onClick={this.selectConsultationType}>Okay</Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog open={!!this.state.showHospitalType} data-test-id="hideHospitalType" onClose={this.hidePopup}>
            <Box style={{
              display: "flex",
              justifyContent: "center",
              width: "380px",
              minHeight: "230px",
              gap: "40px",
              caretColor: "transparent",
              alignItems: "center",
              margin: "auto"
            }}>
              <Box>
                <img
                  style={{
                    width: "40px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    margin: "5px auto",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    background: this.state.isConnectedHospital ? "transparent" : "#d3e3f8"
                  }}
                  src={Homevisit}
                  data-test-id="Homevisit"
                  onClick={() => this.handelIsConnectedHospital(false)}
                />
                <Box style={{ display: "flex", justifyContent: "center", fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929" }}>Home Visit</Box>
              </Box>
              <Box>
                <img
                  style={{
                    width: "47px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    margin: "5px auto",
                    background: this.state.isConnectedHospital ? "#d3e3f8" : "transparent"
                  }}
                  src={Hospitalvisit}
                  data-test-id="Hospitalvisit"
                  onClick={() => this.handelIsConnectedHospital(true)}
                />
                <Box style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", display: "flex", justifyContent: "center" }}>Hospital Visit</Box>
              </Box>
            </Box>
          </Dialog>
          <Dialog open={this.state.showConsultationType} data-test-id="hideConsultationType" onClose={this.hidePopup}>
            <Box style={{
              display: "flex",
              justifyContent: "center",
              width: "450px",
              minHeight: "250px",
              gap: "30px",
              alignItems: "center",
              margin: "auto",
              caretColor: "transparent"
            }}>
              <Box style={{ width: "150px" }}>
                <img
                  style={{
                    width: "40px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    margin: " 5px auto",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    background: "#d3e3f8"
                  }}
                  src={Homevisit}
                  data-test-id="appointment"
                  onClick={() => this.handelIsAppointmentHospital("appointment")}
                />
                <Box style={{ display: "flex", justifyContent: "center", fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929" }}>{this.state.roleType}</Box>
              </Box>
              <Box style={{ width: "150px" }}>
                <img
                  style={{
                    width: "47px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    margin: " 5px auto",
                    background: "transparent"
                  }}
                  src={Hospitalvisit}
                  data-test-id="online"
                  onClick={() => this.handelIsAppointmentHospital("online")}
                />
                <Box style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", display: "flex", justifyContent: "center" }}>Online Consultation</Box>
              </Box>
            </Box>
          </Dialog>
          {this.showPrescription(this.state.openPrescription, this.state.prescriptions)}
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

export default Dashboard;

// Customizable Area Start


const webStyle = {
  availableOutOf: {
    color: '#014866B2',
  },
  availableOutofFontSize: {
    fontSize: "28px"
  },

  hospitalDashboardWrapper: {
    padding: '46px 60px'
  },
  searchFilterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: '20px',
    alignItems: 'center'
  },
  bedInfoCardWrapper: {
    display: "flex",
    alignItems: 'center',
    gridGap: '40px',
    paddingTop: '40px',
    flexWrap: "wrap"
  },
  bedInfoWrapper: {
    paddingTop: "60px"
  },
  bedTypeInfo: {
    color: 'black',
    fontSize: '16px',
    fontFamily: 'SF Pro Text Medium'
  },
  divider: {
    height: '1px',
    width: '60%',
    margin: '9px 0 12px'
  },
  numberOfAvailableBeds: {
    fontSize: '35px',
    fontFamily: 'SF Pro Text Bold',
    color: "#014866"
  },
  bedInfoCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: "0px 4px 40px 0px #0148661A",
    minWidth: '150px',
    width: 'auto',
    maxWidth: '100%',
    padding: '24px 18px',
    boxSizing: 'border-box'
  },
  availableBeds: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '22px'
  },
  hospitalDetailsWrapper: {
    padding: "50px 0"
  },
  hospitalInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridGap: "34px",
    flexWrap: 'wrap'
  },
  apointmentWrapper: {
    border: '2px solid black',
    borderRadius: '8px',
    flex: 1,
    width: '100%',
    padding: '38px 24px',
    boxSizing: 'border-box',
  },
  appointmentDataText: {
    fontFamily: "SF Pro text Medium",
    fontSize: "20px",
    fontWeight: 500,
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  numberOfAppointment: {
    color: "#014866",
    fontSize: '54px',
    lineHeight: '1.1',
    fontWeight: 600,
    fontFamily: "SF Pro text Heavy"
  },

  searchBarContainr: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    gap: '40px'
  },
  searchField: {
    width: "85%",
    margin: "15px 0px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
      border: "1px solid #808080"
    },
    "& input": {
      paddingLeft: "8px",
      paddingTop: "16px",
      fontSize: "16px !important",
      color: "#292929 !important",
      fontFamily: "SF Pro Text Regular !important",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "16px !important",
        fontFamily: "SF Pro Text Regular !important",
      }
    },
  },
  sosImage: {
    width: "100px",
    height: "100px",
    cursor: "pointer",
  },
  bannerImage: {
    width: "100%",
    objectFit: "cover" as objectFitType,
    display: "block",
    borderRadius: "8px",
    margin: "5% 0% 10%"
  },
  serviceText: {
    fontSize: "22px",
    color: "#292929",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Bold",
  },
  Services: {
    caretColor: "transparent",
    color: "#3780E0",
    fontSize: "16px",
    fontFamily: "SF Pro Text Semibold",
  },
  ServiceBtn: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "30px",
    cursor: "pointer"
  },
  serviceName: {
    caretColor: "transparent",
    display: 'block',
    position: 'absolute' as Position,
    top: '80%',
    left: '50%',
    margin: '0 auto',
    transform: 'translate(-50%,-50%)',
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#fff"
  },
  imageText: {
    position: 'absolute' as Position,
    top: 0,
    left: 0,
    width: '100%',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#ffffff',
    textAlign: 'center' as textAlignType
  },
  serviceBox: {
    width: "55%"
  },
  serviceBoxTwo: {
    width: "105%",
    display: "flex",
    justifyContent: "flex-end",
    gridColumnGap:'40px'
  },
  serviceContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    position: 'relative' as Position,
    borderRadius: "8px",
    width: '100%',
    height: '150px',
    backgroundSize: 'cover',
    cursor: "pointer"
  },
  dialogPaper: {
    minWidth: "550px",
    borderRadius: "10px"
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "5px 30px 10px",
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    paddingLeft: "40px",
    color: "#292929"
  },
  closeBtnIcon: {
    padding: "12px 0px 12px 12px !important"
  },
  content: {
    padding: "10px 26px 20px 32px",
    scrollbarWidth: "thin !important" as "thin",
    scrollbarColor: "#d9d5d5 #fff !important",
  },
  docList: {
    marginTop: "12px",
    borderBottom: "2px solid #e8e7e7",
  },
  container: {
    display: "flex",
  },
  blockOne: {
    width: "20%",
    display: "block",
    margin: "auto"
  },
  blockTwo: {
    width: "40%",
    display: "block",
    margin: "auto"
  },
  blockThree: {
    width: "40%",
    display: "block",
    float: "right",
    textAlign: "right",
    margin: "auto 0px auto 13px"
  } as any,
  userName: {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929"
  },
  status: {
    caretColor: "transparent",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    color: "#808080"
  },
  day: {
    fontSize: "14px",
    fontFamily: "SF Pro Text Medium",
    color: "#014866",
    caretColor: "transparent",
  },
  time: {
    caretColor: "transparent",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    color: "#AC8E68"
  },
  avatar: {
    width: "85px",
    height: "85px",
    borderRadius: "50%"
  },
  button: {
    color: "#3780E0",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    border: "2px solid #2196f3",
    margin: "8px 110px 30px",
    padding: "8px 30px",
    borderRadius: "8px",
    maxHeight: "56px",
    maxWidth: "154px",
    textTransform: "capitalize" as "capitalize",
  },
};
// Customizable Area End

