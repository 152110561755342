import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Card,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  CardContent,
  withStyles,
} from "@material-ui/core";
import Reservation2Controller, { Props } from "./Reservations2Controller";
import { paymentSuccess } from "./assets";
import { OverflowXProperty, PositionValue, ScrollbarType, floatType, textTransform } from "./types";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import '../../../web/src/fonts/stylesheet.css';
import { Link } from "react-router-dom";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  },
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1500,
      md: 1366,
      sm: 1024,
      xs: 768,
    },
  },
});

// Customizable Area End
export class Reservations2ViewHospitalBooking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    try {
      await Promise.all([this.getHospitalInfo(), this.getInsuranceList()]);
      const queryParams = new URLSearchParams(window.location.search);
      const success = queryParams.get('success')
      if (success === "true") {
          this.setState({ paymentSuccess: true })
      } else {
          this.setState({ paymentSuccess: false })
      }
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
  }

  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.hospitalBookingSidebar}>
          <SidebarNotificationWrapper {...this.props} >
            <Box sx={webStyle.hospitalBookingMainContent}>
              <Box sx={webStyle.root}>
                <Box style={{display:"flex",alignItems:'center',marginBottom: "45px",}}>
                  <Box style={webStyle.reservationEditBackButtons} onClick={this.backToBookingPage}>
                      <ArrowBackIosIcon data-test-id="hospitalBackBtn" style={webStyle.backArrowIcon} onClick={this.backToBookingPage}/>
                  </Box>
                  <Typography variant="h6" className={classes.profileHeading}>
                    Profile
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item xl={8} md={10} sm={12} xs={12}>
                    <Box style={webStyle.topLeftContainer}>
                      <Box style={webStyle.profileContainer}>
                        <img
                          className={classes.avatar} src={this.checkProfile(this.state.hpList)}
                        />
                        
                      <Box style={webStyle.profileDetailsContainer}>
                        <Typography variant="h6" className={classes.headingName}>
                          {this.state.hpList?.attributes?.name}
                        </Typography>
                        <Typography variant="h6" style={webStyle.subHeading}>
                          {this.state.hpList?.attributes?.address?.data?.attributes?.city}
                        </Typography>
                      </Box>
                      </Box>
                      <Box>
                          <Button
                            data-test-id="bookingBtn"
                            variant="contained"
                            color="primary"
                            className={classes.bookingBtn}
                            onClick={() => this.navigateToHospitalBooking(this.props.navigation.getParam("navigationBarTitleText"))}
                          >
                            Book Now
                          </Button>
                      </Box>
                    </Box>
                    <Box style={{display:"flex", gap:"30px",marginBottom:"42px"}}>
                        <Button
                          variant="contained"
                          data-test-id="insurance"
                          color="primary"
                          style={webStyle.listBtn}
                          onClick={()=>this.openInsurancePopup()}
                        >
                          Insurance
                        </Button>
                         {this.state.showInsurance && 
                         (
                         <Box
                         className={classes.PaperPropsClass}
                          data-test-id="showInsuranceList"
                        >
                          <Box className={classes.popoverContainer}>
                            <Box className={classes.selecthospitalContainer}>
                              <Typography variant="h6" style={webStyle.popupHeading}>
                                Available Insurance Scheme
                              </Typography>
                              <Typography variant="h6" data-test-id="closeInsurancePopover" style={webStyle.clsBtn} onClick={()=>this.openInsurancePopup()}>
                                <CloseIcon/>
                              </Typography>
                            </Box>
                            <Box style={webStyle.tablePopoverContainer}>
                              <TableContainer style={webStyle.tableContainer}>
                                <Table>
                                  <TableBody>
                                    {this.state.hpList?.attributes?.insurances?.length ? (
                                        this.state.hpList.attributes.insurances.map((item: any) => (
                                          <TableRow data-test-id="insurances" key={item.id}>
                                            <TableCell data-test-id="insurancesName" style={webStyle.tableCell}>
                                              {item?.name}
                                            </TableCell>
                                          </TableRow>
                                        ))
                                      ) : (
                                        <>
                                          <Box data-test-id="insurances" style={webStyle.noData}>Data not found</Box>
                                        </>
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </Box>
                        </Box>
                         )}
                         <Button
                          variant="contained"
                          data-test-id="departments"
                          color="primary"
                          style={webStyle.listBtn}
                          onClick={()=>this.openDepartmentPopup()}
                        >
                          Departments
                        </Button>
                        {this.state.showDepart && 
                         (
                         <Box
                         className={classes.PaperPropsClass}
                          data-test-id="showDepartmentsList"
                        >
                          <Box className={classes.popoverContainer}>
                            <Box className={classes.selecthospitalContainer}>
                              <Typography variant="h6" style={webStyle.popupDepartHeading}>
                                Available Departments
                              </Typography>
                              <Typography variant="h6" data-test-id="closeDepartsPopover" style={webStyle.clsBtn} onClick={()=>this.openDepartmentPopup()}>
                                <CloseIcon/>
                              </Typography>
                            </Box>
                            <Box style={webStyle.tablePopoverContainer}>
                              <TableContainer style={webStyle.tableContainer}>
                                <Table>
                                  <TableBody>
                                    {this.state.hpList?.attributes?.departments?.length ? (
                                      this.state.hpList.attributes.departments.map((item: any) => (
                                        <TableRow data-test-id="department" key={item.id}>
                                          <TableCell data-test-id="departmentName" style={webStyle.tableCell}>
                                            {item?.name}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <>
                                        <Box data-test-id="department" style={webStyle.noData}>Data not found</Box>
                                      </>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </Box>
                        </Box>
                         )}
                      </Box>
                      <Box style={webStyle.boxBottomBorder}/>
                      <Box style={webStyle.subContainer}>
                          <Typography style={webStyle.userDetailsLabel}>
                              Bed Types
                          </Typography>
                          <Typography style={webStyle.userDetailsColon}>
                              :
                          </Typography>
                          <Typography className={classes.userDetails}>
                          {this.state.hpList?.attributes?.categories.length > 0 && (
                            <>
                              {this.state.hpList.attributes.categories.map((category:any, index:any) => (
                                <span key={index}>{category}{index !== this.state.hpList.attributes.categories.length - 1 ? ", " : ""}</span>
                              ))}
                            </>
                          )}
                          </Typography>
                      </Box>
                      <Box style={webStyle.subContainer}>
                          <Typography style={webStyle.userDetailsLabel}>
                              Email Address
                          </Typography>
                          <Typography style={webStyle.userDetailsColon}>
                              :
                          </Typography>
                          <Typography className={classes.userDetails}>
                            {this.state?.hpList?.attributes?.email}
                          </Typography>
                      </Box>
                      <Box style={webStyle.boxBottomBorder}/>
                  </Grid>
                </Grid>
              </Box>
              <Dialog open={this.state.paymentSuccess}
                PaperProps={{
                  style: {
                    borderRadius: "20px",
                  }
                }}
              >
                <Card
                  data-test-id="ccAvenueCardContainer" style={webStyle.paymentNewTransparentCard}
                >
                  <CardContent
                    style={webStyle.paymentCardContentNew}
                  >
                    <img
                      src={paymentSuccess}
                      style={webStyle.successIcon}
                      alt="ccAvenuePtmVerified"
                    />
                    <Typography
                      variant="h6"
                      style={webStyle.paymentSuccessMessage} 
                    >
                      Appointment booked successfully</Typography>
                    <Button
                      data-test-id="ccAvenuePtmVerified"
                      variant="contained"
                      color="primary"
                      style={webStyle.successPaymentBtn}
                      onClick={this.handelCloseHospitalSuccessPopup}
                    >
                      Done
                    </Button>
                  </CardContent>
                </Card>
              </Dialog>
            </Box>
            </SidebarNotificationWrapper>
          </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  hospitalBookingSidebar: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty,
  },
  hospitalBookingMainContent: {
    height: "auto",
  },
  root: {
    padding: "48px 60px",
    '@media(max-width:600px)':{
      padding:'24px' 
    }
  },
  profileHeading: {
    width: "100%",
    fontSize: "28px",
    
    fontFamily: "SF Pro Text Bold",
    caretColor:"transparent",
    color:"#292929",
    '@media(max-width:600px)':{
      fontSize:"18px"
    }
  },
  topLeftContainer: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 35px 0px",
    gap:'24px',
    flexWrap:'wrap' as const
  },
  profileContainer:{
    display:'flex',
    alignItems:'center',
    gap:"24px"
    // width:"20%",
    // [theme.breakpoints.down("md")]: {
    //   width: "40%"
    // },
    // [theme.breakpoints.down("sm")]:{
    //   width: "50%"
    // }
  },
  avatar: {
    borderRadius: "50%",
    width: "138px",
    height: "138px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
    '@media(max-width:600px)':{
      width:'90px',
      height:'90px'
    }
  },
  profileDetailsContainer:{

  },
  headingName: {
    fontSize: "24px",
    color:"#292929",
    fontFamily: "SF Pro Text Bold",
    caretColor:"transparent",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "53px",
    WebkitBoxOrient: "vertical", 
    WebkitLineClamp: 1, 
    display: "-webkit-box", 
    lineHeight: "26px",
    '@media(max-width:600px)':{
      fontSize:'18px'
    }
  } as any,
  subHeading: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor:"transparent",
    color: "#808080",
    maxWidth: "200px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1, 
    textOverflow: "ellipsis",
    overflow: "hidden"
  } as any,
  bookingBtn: { 
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "9px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily:"SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#F2F6F7"
    },
    textTransform: "capitalize" as textTransform,
    width: "200px",
    height:"56px",
    float:"right" as floatType,
    '@media(max-width:600px)':{
      width:'100%'
    }
  },
  listBtn: { 
    color: "#014866",
    backgroundColor: "#fff",
    borderRadius: "9px",
    cursor: "pointer",
    fontSize: "18px",
    border:"1px solid #014866",
    fontFamily:"SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    width: "200px",
    height:"56px",
    caretColor:"transparent"
  },
  boxBottomBorder: 
  {
    width: "375px",
    borderBottom: "1px solid #D8D8D8",
    marginTop: "30px"
  },
  subContainer: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "22px 0px 0px",
    maxWidth: "430px"
  },
  userDetailsLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    width: "100%",
    color:"#292929"
  },
  userDetailsColon: {
    caretColor:"transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    width: "8%",
    color: "#808080"
  },
  userDetails: {
    caretColor:"transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    width: "100%",
    textAlign:'left' as const,
    color: "#808080",
  },
  tableCell:{
    padding: "10px 0px",
    fontFamily: "SF Pro Text Medium",
    borderBottom:"1px solid rgb(231, 228, 228)",
    fontSize: "16px",
    maxHeight: "100px",
    caretColor:"transparent",
    color:"#292929"
  },
  selecthospitalContainer:{
    display: "flex",
    justifyContent: "space-between",
    width:"360px",
    '@media(max-width:600px)':{
      width:'100%'
    }
  },
  popupHeading: {
    display: "flex",
    caretColor:"transparent",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    marginLeft: "8%",
    marginTop: "5px",
    fontFamily:"SF Pro Text Bold",
    color:"#292929",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "32%"
    }
  },
  popupDepartHeading: {
    display: "flex",
    caretColor:"transparent",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily:"SF Pro Text Bold",
    color:"#292929",
    fontSize: "18px",
    marginLeft: "16%",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "32%"
    }
  },
  clsBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "8px",
    marginTop: "5px",
    fontSize: "22px",
    cursor: "pointer",
    caretColor:"transparent"
  },
  tablePopoverContainer: {
    scrollbarColor:" #d9d5d5 #fff",
    maxHeight:"80vh",
    margin:"5% 0px",
    overflowY:"scroll" as OverflowXProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    '&::-webkit-scrollbar': {
      backgroundColor: 'white',
      color:"white"
    },
    '&::-webkit-scrollbar-button': {
      backgroundColor: 'white',
      display:"none",
      color:"white"
    },
    '@media(max-width:991px)': {
      maxHeight:'330px',
    },
    '@media(max-width:600px)': {
      maxHeight:'300px',
    },
  },
  popoverContainer: {
    position: 'fixed' as PositionValue,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '18px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    width: '360px',
    padding: '20px',
    '@media(max-width:600px)': {
      width: '80%',
    },
  },
  PaperPropsClass: {
    backgroundColor: '#050505a3',
    position: "fixed" as PositionValue,
    top: 0,
    left: 0,
    marginLeft:"8.30%",
    width: "100%",
    height: "100%",
    zIndex: 999,
    '@media(max-width:991px)':{
      marginLeft:'0'
    }
  },
  tableContainer: {
    width: "96%",
  },
  reservationEditBackButtons: {
    display: "flex",
    alignItems: "center",
    width: "26px",
    height: "28px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px 10px 10px 13px",
    justifyContent: "center",
    marginRight: "20px",
    borderRadius: "5px",
    caretColor:"transparent",
  },
  backArrowIcon: {
    caretColor:"transparent",
    height: "17px",
    weight: "17px"
  },
  noData:{
    display:"flex",
    margin:"auto",
    fontFamily:"SF Pro Text Regular",
    fontSize:"18px",
    color:"#808080",
    justifyContent:"center"
  },
paymentNewTransparentCard: {
  width: "380px",
  borderRadius: "25px",
  padding: "15px 35px",
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
},
paymentCardContentNew: {
  display: "flex",
  flexDirection: "column" as "column"
},
paymentSuccessLogo: {
  width: "85px",
  caretColor: "transparent",
  margin: "20px auto",
},
paymentSuccessMessage: {
  fontSize: "20px",
  margin: "30px auto",
  caretColor: "transparent",
  color: "#292929",
  fontFamily: "SF Pro Text bold",
  textAlign: "center" as "center"
},
successPaymentBtn: {
  width: "100%",
  textTransform: "capitalize" as "capitalize",
  fontSize: "18px",
  caretColor: "transparent",
  backgroundColor: "#014866",
  color: "#fff",
  border: "none",
  borderRadius: "10px",
  padding: "16px",
  cursor: "pointer",
  margin: "10px auto",
  fontFamily: "SF Pro Text Bold",
  "&:hover": {
    backgroundColor: "#014866",
    color: "#fff"
  }
},
successIcon: {
  objectFit: "contain" as "contain", 
  margin: "20px auto auto",
  width: "150px", 
  height: "150px" ,
  caretColor:"transparent"
}
};

export default withStyles(webStyle)(Reservations2ViewHospitalBooking)
// Customizable Area End

