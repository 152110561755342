import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  withStyles,
} from "@material-ui/core";
import DashboardController, { Props } from "../DashboardController";
import Loader from "../../../../components/src/Loader.web";
import SidebarNotificationWrapper from "../../../notifications/src/Notifications.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const theme = createTheme({
  overrides:{
    MuiOutlinedInput : {
      root:{
        '&:hover .MuiOutlinedInput-notchedOutline':{
          borderColor:'#0000ff',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
          borderColor:'#0000ff',
        },
        '& input':{
          color:'#292929',
          fontSize:'16px',
          cursor:'pointer',
          fontFamily:'SF Pro Text Regular',
          '&::placeholder':{
            opacity:1,
            fontSize:'16px',
            fontFamily:'SF Pro Text Regular',
            color:'#808080',
          },
        },
      },
    },
    MuiInputLabel : {
      root : {
        '&.Mui-focused': {
          color : '#0000ff',
        },
      },
    },
  },
  palette:{
    primary:{
      main:"#0000ff",
      contrastText:"#fff"
    }
  },
});

// Customizable Area End
export class Prescriptions extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.fetchPrescription()
  }

  showHPMedicineDetails = (classes:any,patientHPPrescriptions:any) => {
    return (
      <Box>
        {patientHPPrescriptions?.attributes?.prescribed_medications.map((item: any, index: number) => (
          <Box className={classes.containerDashboardBackground} key={index}>
            <Typography className={classes.homecureDashboardLabel} variant="h4">{item.data.attributes.medication_type}</Typography>
            <Box>
              <Typography className={classes.homecureDashboardLabel} variant="h4">Name of the Medicine</Typography>
              <Typography className={classes.text} variant="h4">{item.data.attributes.name}</Typography>
            </Box>
            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Box style={{ width: "50%" }}>
                <Typography className={classes.homecureDashboardLabel}  variant="h4">Dosage</Typography>
                <Typography style={{ ...webStyle.text, textTransform: "lowercase" }} variant="h4">{item.data.attributes.dosage} {item.data.attributes.medication_type}</Typography>
              </Box>
              <Box style={{ width: "50%" }}>
                <Typography className={classes.homecureDashboardLabel} variant="h4">Duration</Typography>
                <Typography className={classes.text} variant="h4">{item.data.attributes.duration}</Typography>
              </Box>
            </Box>
            <Box style={{ width: "100%", justifyContent: "space-between", display: "flex"}}>
              <Box style={{width: "50%"}}>
                <Typography className={classes.homecureDashboardLabel} variant="h4">Repeat</Typography>
                <Typography className={classes.text} variant="h4">{item.data.attributes.repeat}</Typography>
              </Box>
              <Box style={{width: "50%"}}>
                <Typography className={classes.homecureDashboardLabel} variant="h4">Time of the day</Typography>
                <Typography className={classes.text} variant="h4">{item.data.attributes.time_of_the_day.join(' and ')}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography className={classes.homecureDashboardLabel} variant="h4">To be taken</Typography>
              <Typography className={classes.text} variant="h4">{item.data.attributes.to_be_taken.join(' and ')}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    )
  }

  showHPDiagnosis = (patientHPPrescriptions:any) => {
    return (
      <>
        {patientHPPrescriptions?.attributes?.diagnosis.length > 0 ?
          (<Box>
            {patientHPPrescriptions?.attributes?.diagnosis.map((test: any, index: number) => (
              <Box key={index}>
                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel} data-test-id="line3">Line {index + 1}</Typography>
                <Typography variant="h4" className={this.props.classes.text} data-test-id="mention3">{test === "" ? "Mention here" : test}</Typography>
              </Box>
            ))}
          </Box>) :
          (
            <Box>
              <Box>
                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel} data-test-id="line1">Line 1</Typography>
                <Typography variant="h4" className={this.props.classes.text} data-test-id="mention1">Mention here</Typography>
              </Box>
              <Box>
                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel} data-test-id="line2">Line 2</Typography>
                <Typography variant="h4" className={this.props.classes.text} data-test-id="mention2">Mention here</Typography>
              </Box>
              <Box>
                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel} data-test-id="line3">Line 3</Typography>
                <Typography variant="h4" className={this.props.classes.text} data-test-id="mention3">Mention here</Typography>
              </Box>
            </Box>
          )}</>
    )
  }

  diagnosisHPTest = (patientHPPrescriptions:any) => {
    return (<>
      {patientHPPrescriptions?.attributes?.diagnosis_tests.map((test: any, index: number) => (
        <Typography
          key={index}
          variant="h4"
          style={{ ...webStyle.text, marginTop: index === 0 ? 0 : '10px' }}
          data-test-id={test.toLowerCase().replace(/ /g, '_')}
        >
          {test}
        </Typography>
      ))}
    </>
    )
  }

  showHPAdvice = (classes:any,patientHPPrescriptions:any) => {
    return (
      <>
        {patientHPPrescriptions?.attributes?.general_advices.length > 0 ? (
          <>
            {patientHPPrescriptions?.attributes?.general_advices.map((advice: string, index: number) => (
              <Box key={index}>
                <Typography className={classes.homecureDashboardLabel} variant="h4" >Line {index + 1}</Typography>
                <Typography className={classes.text} variant="h4" >{advice === "" ? "Mention here" : advice}</Typography>
              </Box>
            ))}
          </>
        ) : (
          <>
            <Box>
              <Box>
                <Typography className={classes.homecureDashboardLabel} variant="h4" >Line 1</Typography>
                <Typography className={classes.text} variant="h4" >Mention here</Typography>
              </Box>
              <Box>
                <Typography className={classes.homecureDashboardLabel} variant="h4" >Line 2</Typography>
                <Typography className={classes.text} variant="h4" >Mention here</Typography>
              </Box>
              <Box>
                <Typography className={classes.homecureDashboardLabel} variant="h4" >Line 3</Typography>
                <Typography className={classes.text} variant="h4" >Mention here</Typography>
              </Box>
            </Box>
          </>
        )}
      </>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Loader loading={this.state.isLoading} />
          <SidebarNotificationWrapper {...this.props}>
            {this.state.profileType === "" && (
              <>
                Loading...
              </>
            )}
            {(this.state.profileType === "patient" && this.state.patientPrescriptions !== null) && (
              <Box className={this.props.classes.hospitalDashboardWrapper}>
                <>
                  <Box>
                    <Box className={this.props.classes.dialogPrescriptionHeader}>
                      <Box className={this.props.classes.orderHeadingView}>
                        <ArrowBackIosIcon
                          className={this.props.classes.headingIconView}
                          data-test-id="backToPreviousScreen"
                          onClick={() => this.redirectingToDashboardPage()}
                        />
                        <Typography className={this.props.classes.profileHPHeadingView}>Previous Prescription</Typography>
                      </Box>
                      <Typography className={this.props.classes.date}>{this.state.patientPrescriptions.attributes.create_date}</Typography>
                    </Box>
                    <Box>
                      {
                        <img
                          className={this.props.classes.docCover}
                          alt="document"
                          src={this.state.patientPrescriptions.attributes.file_details.url}
                        />
                      }
                    </Box>
                  </Box>
                </>
              </Box>
            )}
            {(this.state.profileType !== "patient" && this.state.patientHPPrescriptions !== null) && (
              <Box style={{ padding: "45px 60px" }}>
                <Box className={this.props.classes.headerHPContainerView}>
                  <Box className={this.props.classes.orderHeadingView}>
                    <ArrowBackIosIcon
                      className={this.props.classes.headingIconView}
                      data-test-id="backToPreviousScreen"
                      onClick={() => this.redirectingToDashboardPage()}
                    />
                    <Typography variant="h4" className={this.props.classes.profileHPHeadingView}>Prescription Overview</Typography>
                  </Box>
                </Box>
                <Grid container >
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box className={this.props.classes.inputContainerView}>
                      <Typography variant="h4" className={this.props.classes.homeCureHeadingView}>HomeCure</Typography>
                      <Box className={this.props.classes.seperationLineView} />
                      <Box style={{ width: "100%" }}>
                        <Box>
                          <TextField
                            variant="outlined"
                            placeholder="Hospital Name"
                            className={this.props.classes.inputNameFieldsView}
                            value={this.state.patientHPPrescriptions.attributes?.hospital_name}
                          />
                        </Box>
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <Box>
                          <TextField
                            variant="outlined"
                            placeholder="City"
                            className={this.props.classes.inputCityFieldsView}
                            value={this.state.patientHPPrescriptions.attributes?.hospital_address}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={4}>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Heathcare Personnel Details</Typography>
                        <Box className={this.props.classes.containerBackground}>
                          <Box>
                            <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Heathcare Personnel Name</Typography>
                            <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.healthcare_personnel_name}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Degree</Typography>
                            <Box style={{ display: "flex", flex: "wrap", gap: "10px" }}>
                              <Typography variant="h4" style={{ ...webStyle.text, background: "#fff", padding: "4px 6px", borderRadius: "8px" }}>{this.state.patientHPPrescriptions?.attributes?.degree}</Typography>
                            </Box>
                          </Box>
                          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Date</Typography>
                              <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.date}</Typography>
                            </Box>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Time</Typography>
                              <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.time}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Consult Type</Typography>
                            <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.consult_type}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Consult ID</Typography>
                            <Typography variant="h4" className={this.props.classes.text}>{this.props.navigation.getParam("navigationBarTitleText")}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xl={8} md={8} sm={12} xs={12}>
                        <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Patient Detials</Typography>
                        <Box className={this.props.classes.containerBackground}>
                          <Grid container spacing={4}>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box>
                                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Patient Name</Typography>
                                <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.patient_name}</Typography>
                              </Box>
                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Age</Typography>
                                  <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.age}</Typography>
                                </Box>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Gender</Typography>
                                  <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.gender}</Typography>
                                </Box>
                              </Box>
                              <Box className={this.props.classes.seperationLine} />
                              <Box>
                                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Vitals</Typography>
                              </Box>
                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Blood Pressure</Typography>
                                  <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.vitals.blood_pressure} mmHg</Typography>
                                </Box>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Pulse</Typography>
                                  <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.vitals.pulse} / minute</Typography>
                                </Box>
                              </Box>
                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Height</Typography>
                                  <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.vitals.height} cm</Typography>
                                </Box>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Weight</Typography>
                                  <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.vitals.weight} kg</Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Temperature</Typography>
                                <Typography variant="h4" className={this.props.classes.text}>{this.state.patientHPPrescriptions?.attributes?.vitals.temperature} celcius</Typography>
                              </Box>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box>
                                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Chief Complaints</Typography>
                                {this.state.patientHPPrescriptions?.attributes?.chief_complaint.length > 0 ? (<>
                                  {this.state.patientHPPrescriptions?.attributes?.chief_complaint.map((complaint: any, index: number) => (
                                    <Box key={index}>
                                      <Typography variant="h4" className={this.props.classes.text} key={index}>{complaint}</Typography>
                                    </Box>
                                  ))}</>) : (
                                  <Typography variant="h4" className={this.props.classes.text}>No complaint found</Typography>
                                )}
                              </Box>
                              <Box className={this.props.classes.seperationLine} />
                              <Box>
                                <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Details</Typography>
                              </Box>
                              {this.state.patientHPPrescriptions?.attributes?.details.length > 0 ? (
                                <>
                                  {this.state.patientHPPrescriptions?.attributes?.details.map((details: any, index: number) => (
                                    <Box key={index}>
                                      <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Line {index + 1}</Typography>
                                      <Typography variant="h4" className={this.props.classes.text}>{details === "" ? `Detail ${index + 1}` : details}</Typography>
                                    </Box>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <Box>
                                    <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Line 1</Typography>
                                    <Typography variant="h4" className={this.props.classes.text}>Detail 1</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Line 2</Typography>
                                    <Typography variant="h4" className={this.props.classes.text}>Detail 2</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Line 3</Typography>
                                    <Typography variant="h4" className={this.props.classes.text}>Detail 3</Typography>
                                  </Box>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xl={4} md={4} sm={12} xs={12}>

                        <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Diagnosis</Typography>
                        <Box className={this.props.classes.containerDashboardBackground} data-test-id="diagnosis">
                          {this.showHPDiagnosis(this.state.patientHPPrescriptions)}
                          <Box className={this.props.classes.seperationLine} data-test-id="line" />
                          <Box>
                            <Typography variant="h4" className={this.props.classes.homecureDashboardLabel} data-test-id="diagnosistTest">Diagnosist Test</Typography>
                            {this.diagnosisHPTest(this.state.patientHPPrescriptions)}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>Medicines</Typography>
                        <Box>
                          {this.showHPMedicineDetails(this.props.classes,this.state.patientHPPrescriptions)}
                        </Box>
                      </Grid>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={this.props.classes.homecureDashboardLabel}>General Advices</Typography>
                        <Box className={this.props.classes.containerDashboardBackground}>
                          {this.showHPAdvice(this.props.classes,this.state.patientHPPrescriptions)}
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" className={this.props.classes.signatureLabel}>Signature</Typography>
                    {this.state.patientHPPrescriptions?.attributes?.signature_path !== null &&
                      (<img
                        src={this.state.patientHPPrescriptions?.attributes?.signature_path.url}
                        style={{ display: 'flex', cursor: 'pointer', maxHeight: "100%", maxWidth: "30%" }}
                      />)}
                  </Grid>
                </Grid>
              </Box>
            )}
          </SidebarNotificationWrapper>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  hospitalDashboardWrapper: {
    padding: '46px 60px'
  },
  dialogPrescriptionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  presBackButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    caretColor: "transparent",
    margin: "35px",
    borderRadius: "5px",
    "& .MuiSvgIcon-root": {
      marginLeft: "10px"
    }
  },
  date: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 600,
    fontSize: "15px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium",
    color: "#e3e3e3",
    marginBottom:"40px",
  },
  docCover: {
    width: "450px",
    display: "flex",
    minHeight: "400px",
    maxHeight: "600px",
    margin: "15px 0px 0px 108px",
    objectFit: "contain" as "contain"
  },
  profileHPHeadingView: {
    width: "100%",
    caretColor: "transparent",
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
  },
  headerHPContainerView: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderHeadingView: {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    marginBottom: "40px",
    justifyContent: "flex-start",
  },
  headingIconView: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    padding: "13px 9px 13px 17px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
  },
  inputNameFieldsView: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important"
    },
    "& .MuiInputBase-root": {
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      fontSize: "14px",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      border: "none",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        color: "#9D9D9D !important",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputCityFieldsView: {
    width: "100%",
    borderRadius: "8px",
    fontSize: "14px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      border: "none",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      borderLeft: "1px solid #DBDBDB",
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px"
    },
    "& input": {
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      color: "#292929",
      border: "none",
      "&::placeholder": {
        fontSize: "14px",
        opacity: 1,
        color: "#9D9D9D !important",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important",
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputContainerView: {
    display: "flex",
    background: "#EBF3FC",
    alignItems: "center" as "center",
    alignContent: "center" as "center",
    padding: "15px",
    marginBottom: "30px",
    width: "60%"
  },
  homeCureHeadingView: {
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#014866",
    marginRight: "10px",
    '@media (max-width: 600px)': {
      display: "none"
    },
    caretColor: "transparent"
  },
  seperationLineView: {
    borderBottom: "6px solid #02A405",
    width: "176px",
    transform: "rotate(90deg)",
    borderBottomRightRadius: "3px",
    borderBottomLeftRadius: "3px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    marginRight: "5px",
    '@media (max-width: 600px)': {
      display: "none"
    }
  },
  homecureDashboardLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent",
    margin: "15px auto 8px"
  },
  signatureLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent",
    margin: "25px auto 8px"
  },
  containerBackground: {
    background: "#f1f0f0",
    minHeight: "375px",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px"
  },
  containerDashboardBackground: {
    background: "#f1f0f0",
    minHeight: "300px",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px"
  },
  text: {
    color: "#9D9D9D !important",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    textTransform: "capitalize" as "capitalize"
  },
  dateTimeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  seperationLine: {
    background: "#DDD",
    border: "none",
    width: "100%",
    margin: "10% auto auto",
    height: "1px"
  }
};

export default withStyles(webStyle)(Prescriptions);
// Customizable Area End

