import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";
import { withStyles, CardContent, Typography, Box, ThemeProvider, createTheme } from "@material-ui/core";
import { FlexDirectionProperty } from "./types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { UserProfile } from "./assets";
import StarIcon from '@material-ui/icons/Star';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";


const theme = createTheme({
    palette: {
      primary: {
        contrastText: "#fff",
        main: "#0000ff",
      }
    }
  });


  const reviewerTemplate = {
    image: UserProfile,
    name: 'Lucas Simoes',
    description: 'Conan Matusav is a Registered Charity under the Charities Acts.',
    rating: 4.2,
  };

  const reviewersInfoData = Array(8).fill(reviewerTemplate);

  

// Customizable Area End
export class Reservations2NurseProfileReviews extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  IndividualReviewerInfoCard = (user: any) => {
    const {classes}  = this.props;
  return <>
      <Box id="singleReviewerBox" style={webStyles.nurseReviewFlexbox}>
        <img style={webStyles.nurseReviewerCover} src={user.image} title={user.name} />
          <CardContent style={webStyles.nurseReviewerContent}>
              <div>
                <Typography className={classes.nurseReviewerName} component="h5" variant="h5">
                  {user.name}
                </Typography>
                <Typography className={classes.nurseReviewerDescription} component="h5" variant="h5">
                  {user.description}
                </Typography>
              </div>
              <div className={classes.nurseReviewerOverallRating}>
                <StarIcon />
                <Typography variant="body2" style={{ marginLeft: '4px' }}>
                  <span style={webStyles.nurseReviewerRating}>{user.rating}</span><span style={webStyles.nurseReviewerRatingOutOf}> / 5 </span>
                </Typography>
              </div>
          </CardContent>
      </Box>
      <Box id="singleReviewerDivider" style={webStyles.nurseReviewDivider}></Box>
    </>
  };
  // Customizable Area End

  render() {
    const {classes} = this.props;
    return (
      // Customizable Area Start
        <>
            <ThemeProvider theme={theme}>
                <Box style={webStyles.reviewsContainer}>
                  <SidebarNotificationWrapper {...this.props}>
                    <Box>
                        <Box className={classes.availblereviewsContainer}>
                            <Box style={webStyles.reviewsHeaderContainer}>
                                <Box style={webStyles.reviewsHeading}>
                                    <ArrowBackIosIcon
                                        style={webStyles.reviewsBackButton}
                                        data-test-id="reviewsBackBtn"
                                        onClick={() => this.navigateToNurseProfile(this.props.navigation.getParam("navigationBarTitleText"))}
                                    />
                                    <Typography variant="h6" className={classes.reviewsTitle}>
                                        Reviews
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.reviewContainer}>
                                {reviewersInfoData.map((user: any, index: string | number) => (
                                    this.IndividualReviewerInfoCard({...user})
                                ))}
                            </Box>
                        </Box>
                    </Box>
                  </SidebarNotificationWrapper>
                </Box>
            </ThemeProvider>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
    reviewsContainer: {
        display: "flex",
    },
    availblereviewsContainer: {
        padding: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as FlexDirectionProperty,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "20px",
        '@media(max-width:600px)':{
          padding:'24px',
          width:'100%',
          boxSizing:'border-box'
        }
    },
    reviewsHeaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        justifyContent: "space-between",
    },
    reviewsHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
        
    },
    reviewsBackButton: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "12px 8px 12px 16px",
    },
    reviewsTitle: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "33.41px",
        '@media(max-width:600px)':{
          fontSize:'18px'
        }
    },
    reviewContainer: {
        width: "80%",
        '@media(max-width:600px)':{
          width:'100%'
        }
    },
    nurseReviewDivider: {
        width: '100%',
        height: '1px',
        opacity: 0.38,
        borderBottom: '1px solid #C2C2C2',
        margin: '20px 0px',
    },
    reviewHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    reviewTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.87px',
        color: '#292929',
    },
    seeAllReview: {
        color: '#3780E0',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
    },
    nurseReviewFlexbox: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: "20px",
        margin: "15px 0px"
    },
    nurseReviewerCover: {
        width: "65px",
        height: "65px",
        borderRadius: '50%',
    },
    nurseReviewerName: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '21.48px',
        letterSpacing: '-0.4341176450252533px',
        color: '#292929',
        marginBottom: '5px',
        '@media(max-width:600px)':{
          fontSize:'16px'
        }
    },
    nurseReviewerDescription: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '-0.38588234782218933px',
        color: '#808080',
        width: '277px',
        '@media(max-width:600px)':{
          width:'100%',
          fontSize:'14px'
        }
    },
    nurseReviewerContent: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        padding: 0,
    },
    nurseReviewerRating: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        letterSpacing: '-0.02800000086426735px',
        color: '#014866',
        marginLeft: '5px',
    },
    nurseReviewerRatingOutOf: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        letterSpacing: '-0.02800000086426735px',
        color: '#3780E0',
    },
    nurseReviewerOverallRating: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end",
        color: '#4CAF50',
        fontWeight: 600,
        whiteSpace:'nowrap' as const
    },
};

export default withStyles(webStyles)(Reservations2NurseProfileReviews);
// Customizable Area End