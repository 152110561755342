import * as React from "react";
// Customizable Area Start
import {
  withStyles,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  Dialog,
  DialogContent,
  createTheme,
  ThemeProvider,
  CardContent,
  Card
} from "@material-ui/core";
import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Notification,
  Setting,
  Edit,
  camera,
  verifiedEmail,
  gallery,
  ImageIcons,
  EmailIcon,
  otp_success
} from "./assets";
import Webcam from "react-webcam";
import CountDownTimer from "../../../components/src/CountDownTimer.web";
import HospitalProfileWrapper from "./HospitalProfileWrapper.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const themes = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const videoConstraint = {
  width: 450,
  facingMode: "environment"
};
// Customizable Area End

export class CustomisableEditHospitalProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchHospitalProfileDatas();
    }

    showErrorField = (error: string | undefined, classes: any, keys?: string) => {
        return error
        ? classes.errorRegisterInputFields
        : classes.registerInputFields;
    };

    errorMessage = (error: string | undefined, inputError: any) => {
        return error ? (
        <FormHelperText className={inputError}>{error}</FormHelperText>
        ) : null;
    };

    getHospitalClassName = (classes: any, error: string | undefined) => {
        const { isUploadDoc } = this.state;
        if (isUploadDoc) {
        return error
            ? classes.registrationTextFieldError
            : classes.registrationTextField;
        }
        return error
        ? classes.errorRegisterInputFields
        : classes.registerInputFields;
    };

    getHospitalPlaceholder = () => {
        const { isUploadDoc } = this.state;
        if (!isUploadDoc) {
        return "Upload registration certificate";
        }
        return "";
    };

    hospitalError = (classes: any, state: string) => {
        return (
        <Dialog open={!!state}>
            <>
            <Box data-test-id="hospitalError" className={classes.customContainer}>
                <Box style={{ padding: "0px 10px" }}>
                <p className={classes.heading}>Error</p>
                <p className={classes.paragraph}>{state}</p>
                <button
                    className={classes.popupButton}
                    onClick={() =>
                    this.setState({ hospitalprofileErrorMessage: "" })
                    }
                >
                    OK
                </button>
                </Box>
            </Box>
            </>
        </Dialog>
        );
    };

    generateResendComponent = (text: string, testId: string) => (
        <Typography
        variant="h6"
        data-test-id={testId}
        style={{
            color:
            this.state.resendCounter === 5 || !this.state.isResendActive
                ? "#292929"
                : "#014866",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            textTransform: "none",
            fontWeight: 500,
            cursor:
            this.state.resendCounter === 5 || !this.state.isResendActive
                ? "not-allowed"
                : "pointer",
            opacity:
            this.state.resendCounter === 5 || !this.state.isResendActive
                ? 0.5
                : 1,
            caretColor: "transparent"
        }}
        onClick={() => {
            console.log(text);
        }}
        >
        {text}
        </Typography>
    );
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { hospitalErrors } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <ThemeProvider theme={themes}>
                <Box className={classes.leftWrapper}>
                    <SidebarNotificationWrapper hideNotification {...this.props} >
                        <Box className={classes.rightWrapper}>
                        <Box>
                        <HospitalProfileWrapper data-test-id='profile-wrapper' {...this.props} openProfileSelectModal={this.handleOpenDialogs} profilePicture={this.state.profilePicture} profileBackBtn profilePageTitle="Edit Profile" >
                            <Grid container className={classes.editHospitalPageWrapper}>
                            <Grid item xl={4} md={4} sm={6} xs={12}>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    Hospital Name<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="name"
                                    placeholder="Enter your hospital name here"
                                    value={this.state.user_name}
                                    className={this.showErrorField(
                                    hospitalErrors.user_name,
                                    classes
                                    )}
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "user_name",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.user_name,
                                    classes.inputErrors
                                )}
                                </Box>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    District<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="district"
                                    value={this.state.district}
                                    className={this.showErrorField(
                                    hospitalErrors.district,
                                    classes
                                    )}
                                    placeholder="Enter District"
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "district",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.district,
                                    classes.inputErrors
                                )}
                                </Box>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    Email Address<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="email"
                                    value={this.state.email}
                                    className={this.showErrorField(
                                    hospitalErrors.email,
                                    classes
                                    )}
                                    placeholder="Enter your Email Addrss here"
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "email",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.email,
                                    classes.inputErrors
                                )}
                                </Box>
                            </Grid>
                            <Grid item xl={4} md={4} sm={6} xs={12}>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    {" "}
                                    Pin Code<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="pincode"
                                    value={this.state.postalCode}
                                    className={this.showErrorField(
                                    hospitalErrors.postalCode,
                                    classes
                                    )}
                                    placeholder="Enter Pin Code"
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "postalCode",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.postalCode,
                                    classes.inputErrors
                                )}
                                </Box>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    Address<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    data-test-id="address"
                                    variant="outlined"
                                    className={this.showErrorField(
                                    hospitalErrors.address,
                                    classes
                                    )}
                                    placeholder="Enter street name here"
                                    value={this.state.address}
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "address",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.address,
                                    classes.inputErrors
                                )}
                                </Box>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    Registration No. of Hospital
                                    <span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="registrationNo"
                                    className={this.showErrorField(
                                    hospitalErrors.registrationNo,
                                    classes
                                    )}
                                    placeholder="Enter hospital regregistration noistrationNo"
                                    value={this.state.registrationNo}
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "registrationNo",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.registrationNo,
                                    classes.inputErrors
                                )}
                                </Box>
                            </Grid>
                            <Grid item xl={4} md={4} sm={6} xs={12}>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    City<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="city"
                                    className={this.showErrorField(
                                    hospitalErrors.city,
                                    classes
                                    )}
                                    value={this.state.city}
                                    placeholder="Enter city"
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "city",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.city,
                                    classes.inputErrors
                                )}
                                </Box>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    Phone Number<span className={classes.required}>*</span>
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    data-test-id="phone"
                                    placeholder="Enter your Phone number here"
                                    className={this.showErrorField(
                                    hospitalErrors.phoneNumber,
                                    classes
                                    )}
                                    InputProps={{ inputProps: { maxLength: 10 } }}
                                    value={this.state.phoneNumber}
                                    onChange={event =>
                                    this.handleHospitalProfileChanges(
                                        "phoneNumber",
                                        event.target.value.trimStart()
                                    )
                                    }
                                />
                                {this.errorMessage(
                                    hospitalErrors.phoneNumber,
                                    classes.inputErrors
                                )}
                                </Box>
                                <Box className={classes.textFieldContainer}>
                                <Typography
                                    variant="h6"
                                    className={classes.registerLabel}
                                >
                                    Registration Certificate
                                    <span className={classes.required}>*</span>
                                </Typography>
                                {!this.state.isUploadDoc && (
                                    <TextField
                                    id="registrationCertifcateDoc"
                                    variant="outlined"
                                    data-test-id="registrationCertifcateDoc"
                                    value=""
                                    className={this.getHospitalClassName(
                                        classes,
                                        hospitalErrors.image
                                    )}
                                    placeholder={this.getHospitalPlaceholder()}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                            src={ImageIcons}
                                            className={classes.imageIcons}
                                            />
                                        </InputAdornment>
                                        )
                                    }}
                                    onClick={this.handleButtonClick}
                                    />
                                )}
                                <Box onClick={this.handleButtonClick}>
                                    <Typography
                                    style={{
                                        textAlign: "left",
                                        caretColor: "transparent"
                                    }}
                                    variant="h6"
                                    >
                                    {this.state.isUploadDoc && (
                                        <img
                                        className={classes.uploadImage}
                                        src={this.state.image}
                                        style={{ objectFit: "cover" }}
                                        />
                                    )}
                                    </Typography>
                                </Box>
                                {this.errorMessage(
                                    hospitalErrors.image,
                                    classes.inputErrors
                                )}
                                <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    className={classes.imageInput}
                                    onChange={this.handleHospitalUploadDoc}
                                />
                                </Box>
                            </Grid>
                            <Grid item xl={12} md={12} sm={12} xs={12}>
                                <Box style={{marginTop:'30px'}}>
                                <Button
                                    data-test-id="hospitalDetailsSubmit"
                                    variant="contained"
                                    className={classes.saveButtons}
                                    onClick={this.handleHospitalDetailsSubmit}
                                >
                                    Save
                                </Button>
                                </Box>
                            </Grid>
                            </Grid>
                        </HospitalProfileWrapper>
                            <Dialog
                            open={this.state.openDialog}
                            className={
                                this.state.enableWeb
                                ? classes.webDialogBox
                                : classes.dialogBox
                            }
                            onClose={this.handleCloseDialog}
                            >
                            <Box className={classes.dialogTitleConatiner}>
                                <p className={classes.dialogTitle}>
                                Upload Profile Image
                                </p>
                                <CloseIcon
                                data-test-id="closeDocDialog"
                                style={{ cursor: "pointer" }}
                                onClick={this.handleCloseDialog}
                                />
                            </Box>
                            <DialogContent>
                                <>
                                {this.state.enableWeb ? (
                                    <>
                                    {this.state.url ? (
                                        <>
                                        <Box className={classes.dialogBoxWeb}>
                                            <img
                                            data-test-id="captureHospitaldImage"
                                            src={this.state.url}
                                            />
                                        </Box>
                                        <Box className={classes.capturedBtnContainer}>
                                            <Typography
                                            className={classes.button}
                                            data-test-id="saveHospitalCapturePhoto"
                                            >
                                            Save
                                            </Typography>
                                            <Typography
                                            className={classes.button}
                                            onClick={this.closeWebCamera}
                                            >
                                            Cancel
                                            </Typography>
                                        </Box>
                                        </>
                                    ) : (
                                        <>
                                        <Box className={classes.dialogBoxWeb}>
                                            <Webcam
                                            ref={this.webCameraRef}
                                            audio={false}
                                            screenshotFormat="image/jpeg"
                                            videoConstraints={videoConstraint}
                                            />
                                        </Box>
                                        <Box
                                            className={classes.captureBtnContainer}
                                            data-test-id="CaptureHopitalPhoto"
                                            onClick={this.capturePhoto}
                                        >
                                            <Typography className={classes.button}>
                                            Capture
                                            </Typography>
                                        </Box>
                                        </>
                                    )}
                                    </>
                                ) : (
                                    <>
                                    <Box
                                        className={classes.dialogBoxIcons}
                                        data-test-id="customContainer"
                                    >
                                        <img
                                        data-test-id="openHospitalWebToUpload"
                                        className={classes.camera}
                                        src={camera}
                                        />
                                        <img
                                        data-test-id="openHospitalPopupToUpload"
                                        className={classes.gallery}
                                        src={gallery}
                                        onClick={this.handleUploadProfiles}
                                        />
                                        <input
                                        type="file"
                                        className={classes.imageInput}
                                        ref={this.webCameraRef}
                                        onChange={this.handleUploadProfileImages}
                                        />
                                    </Box>
                                    <Box className={classes.dialogBoxText}>
                                        <Typography
                                        data-test-id="takePhoto"
                                        className={classes.uploadCameraText}
                                        >
                                        Take Photo
                                        </Typography>
                                        <p
                                        data-test-id="gallery"
                                        className={classes.uploadText}
                                        >
                                        Gallery
                                        </p>
                                    </Box>
                                    </>
                                )}
                                {this.errorMessage(
                                    this.state.UploadImageError,
                                    classes.profileError
                                )}
                                </>
                            </DialogContent>
                            </Dialog>
                            <Dialog
                            data-tesi-id="success"
                            open={this.state.hospitalSuccess}
                            >
                            <Box className={classes.customContainer}>
                                <Box style={{ padding: "0px 10px" }}>
                                <p className={classes.heading}>Success</p>
                                <p className={classes.paragraph}>
                                    Profile updated successfully.
                                </p>
                                <Button
                                    data-test-id="popupbtn"
                                    className={classes.popupButton}
                                    onClick={this.handelHospitalSuccess}
                                >
                                    OK
                                </Button>
                                </Box>
                            </Box>
                            </Dialog>
                            <Dialog
                            className={classes.otpDialogBoxNew}
                            open={this.state.otpSend}
                            >
                            <Card className={classes.otpNewTransparentCard}>
                                <CardContent
                                className={classes.otpCardContentNew}
                                style={{ flexDirection: "column" }}
                                >
                                <Box className={classes.profileCaredWrapper}>
                                    <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.otpWelcomeTitle}
                                    style={{ textAlign: "center" }}
                                    >
                                    <span className={classes.otpText}>OTP</span>{" "}
                                    Verification
                                    </Typography>
                                    <CloseIcon
                                    data-test-id="closeDialog"
                                    style={{
                                        cursor: "pointer",
                                        caretColor: "transparent",
                                        marginTop: "10px"
                                    }}
                                    onClick={() =>
                                        this.closeOtpPopup("hospital", "phone")
                                    }
                                    />
                                </Box>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className={classes.cardSubtitle}
                                    style={{ textAlign: "center" }}
                                >
                                    Enter OTP
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className={classes.cardInputLabel}
                                    style={{ textAlign: "center" }}
                                >
                                    An 4- Digit code has been sent to {"\n"}+91{""}
                                    {`******${this.state.phoneNumber?.toString().slice(6, 10)}`}
                                </Typography>
                                <Box className={classes.otpUpdateContainer}>
                                    {this.state.phoneOTP.map((value: any, index: any) => (
                                    <TextField
                                        key={index}
                                        className={
                                        this.state.isError
                                            ? classes.inputErrorFieldVerifyOtp
                                            : classes.inputFieldOtpVerify
                                        }
                                        variant="outlined"
                                        size="small"
                                        id={`updateOtpInput${index}`}
                                        data-test-id={`updateOtpInput${index}`}
                                        value={value}
                                        onChange={event =>
                                        this.handleOTPChange(index, event)
                                        }
                                        style={{
                                        margin: "5px auto 0px",
                                        width: "50px",
                                        height: "45px"
                                        }}
                                        inputProps={{
                                        maxLength: 1,
                                        style: {
                                            textAlign: "center"
                                        }
                                        }}
                                    />
                                    ))}
                                </Box>
                                <Box className={classes.errorMessages}>
                                    {this.state.isError && (
                                    <FormHelperText className={classes.otpErrors}>
                                        {this.state.errorMessage}
                                    </FormHelperText>
                                    )}
                                </Box>
                                <CountDownTimer
                                    times={this.state.resendCounter}
                                    isResendActive={this.state.isResendActive}
                                    toggleResend={this.toggleResend}
                                />
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    padding="8px 0px"
                                >
                                    {this.generateResendComponent(
                                    "Resend OTP",
                                    "resendOTP"
                                    )}

                                    {this.generateResendComponent(
                                    "Get OTP by Call",
                                    "resendOTPByCall"
                                    )}
                                </Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-test-id="updateVerifyOtpBtn"
                                    className={classes.verifyOTPNew}
                                    onClick={() => this.verifyOTPData()}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    Verify
                                </Button>
                                </CardContent>
                            </Card>
                            </Dialog>
                            <Dialog
                            open={this.state.emailSend}
                            className={classes.otpDialogBoxNew}
                            >
                            <Card className={classes.transparentOtpCards}>
                                <CardContent
                                className={classes.cardOtpContents}
                                style={{ flexDirection: "column" }}
                                >
                                <Box className={classes.confimPopup}>
                                    <CloseIcon
                                    data-test-id="closeDialoghospital"
                                    style={{
                                        cursor: "pointer",
                                        caretColor: "transparent"
                                    }}
                                    onClick={() =>
                                        this.closeOtpPopup("hospital", "email")
                                    }
                                    />
                                </Box>
                                <img
                                    src={EmailIcon}
                                    alt="email Verified"
                                    className={classes.emailConfirmOtpLogo}
                                    style={{ objectFit: "contain" }}
                                />
                                <Typography
                                    variant="h6"
                                    className={classes.confirmEmailOtpHeading}
                                    style={{ textAlign: "center" }}
                                >
                                    Confirm your email address
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className={classes.confirmOtpEmailText}
                                    style={{ textAlign: "center" }}
                                >
                                    We sent a confirmation email to {this.state.email}
                                </Typography>
                                <Box className={classes.hospitalSuccessMessageContainer}>
                                    <Typography
                                    data-test-id="textMessage1"
                                    variant="h6"
                                    className={classes.confirmEmailOtpSubText}
                                    style={{ textAlign: "center" }}
                                    >
                                    Check your email and click on the
                                    </Typography>
                                    <Typography
                                    data-test-id="textMessage2"
                                    variant="h6"
                                    className={classes.confirmEmailOtpSubText}
                                    style={{ textAlign: "center" }}
                                    >
                                    confirmation link to continue.
                                    </Typography>
                                </Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-test-id="moveToNextPage"
                                    className={classes.successOtpBtns}
                                    onClick={this.confirmEmail}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    Resend
                                </Button>
                                </CardContent>
                            </Card>
                            </Dialog>
                            <Dialog
                            open={this.state.confimEmail}
                            className={classes.otpDialogBoxNew}
                            >
                            <Card className={classes.transparentOtpCards}>
                                <CardContent
                                className={classes.cardOtpContents}
                                style={{ flexDirection: "column" }}
                                >
                                <img
                                    src={verifiedEmail}
                                    alt="email Verified"
                                    className={classes.emailVerified}
                                    style={{ objectFit: "contain" }}
                                />
                                <Typography
                                    variant="h6"
                                    className={classes.emailMessage}
                                    style={{ textAlign: "center" }}
                                >
                                    Your Email Address Verified
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className={classes.successEmailMessages}
                                    style={{ textAlign: "center" }}
                                >
                                    Successfully
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    data-test-id="moveToNextPage"
                                    className={classes.successOtpBtns}
                                    onClick={() => this.otpSuccessEmail("hospital")}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    Done
                                </Button>
                                </CardContent>
                            </Card>
                            </Dialog>
                            <Dialog
                            open={!!this.state.otpVerified}
                            className={classes.otpDialogBoxNew}
                            >
                            <Card
                                data-test-id="otpCardContainer"
                                className={classes.otpNewTransparentCard}
                            >
                                <CardContent
                                className={classes.otpCardContentNew}
                                style={{ flexDirection: "column" }}
                                >
                                <img
                                    src={otp_success}
                                    alt="PhoneVerified"
                                    className={classes.OtpSuccessLogo}
                                    style={{ objectFit: "contain" }}
                                />
                                <Typography
                                    variant="h6"
                                    className={classes.OtpSuccessMessage}
                                    style={{ textAlign: "center" }}
                                >
                                    Phone Verified Successfully
                                </Typography>
                                <Button
                                    data-test-id="moveToNextPageHospiatl"
                                    variant="contained"
                                    color="primary"
                                    className={classes.successOtpBtn}
                                    onClick={() => this.otpSuccess("hospital")}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    Done
                                </Button>
                                </CardContent>
                            </Card>
                            </Dialog>
                        </Box>
                        </Box>
                    </SidebarNotificationWrapper>
                </Box>
                {this.hospitalError(classes, this.state.hospitalprofileErrorMessage)}
                </ThemeProvider>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableEditHospitalProfile);

// Customizable Area End
