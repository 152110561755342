import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Button, ThemeProvider, createTheme, IconButton, Divider } from "@material-ui/core";
import CustomisableUserProfilesController, {
    configJSON,
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { Notification, SOSImage, Setting, Edit } from "./assets"
import Loader from "../../../components/src/Loader.web";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class CustomisableViewUserProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchUserProfileDatas();
    }

    renderPopover = () => {
        const settingsItems = [
            { id: 'goto-about', label: configJSON.settingsItems.AboutUs, page: configJSON.settingsPageName.aboutUs },
            { id: 'termsandcondition', label: configJSON.settingsItems.TermsConditions, page: configJSON.settingsPageName.termsCondition },
            { id: 'privacy-policy', label: configJSON.settingsItems.PrivacyPolicy, page: configJSON.settingsPageName.privacyPolicy },
            { id: 'help', label: configJSON.settingsItems.Help, page: configJSON.settingsPageName.help },
        ];
        const boundingRect = this.state.settingPopoverEl?.getBoundingClientRect();
        const {classes } = this.props;
        return this.state.settingPopoverEl && <>
            <div style={webStyle.overlay} data-test-id='overlay' onClick={this.closeSettingsPopover}></div>
            <Box data-test-id='popover' 
            style={{ 
                ...webStyle.filterModalWrapper,
                left: `${boundingRect && boundingRect.left}px`,
                top: `${boundingRect && boundingRect.bottom + window.scrollY + 20}px`,
                transform: 'translate(-90%,0)'
            }}
            >
                <Box className={classes.settingsOuterWrapper} >
                    <Box style={webStyle.settingsInnerWrapper}>
                        <p className={classes.settingsTitle}>{configJSON.settingsItems.Settings} </p>
                        <div className={classes.hiddenUp} >
                            <Typography className={classes.settingsItem} data-test-id="editProfile" onClick={() => this.openEditProfiles()} >Edit Profile</Typography>
                            <Divider style={webStyle.divider} />
                            <Typography className={classes.settingsItem} data-test-id='goto-notification' onClick={() => this.goToBlock('Dashboard')}>Notifications</Typography>
                            <Divider style={webStyle.divider} />
                        </div>
                        {settingsItems.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <Typography
                                    className={classes.settingsItem}
                                    data-test-id={item.id}
                                    onClick={this.navigateFromSettings(item.page)}
                                >
                                    {item.label}
                                </Typography>
                                {index < settingsItems.length - 1 && <Divider style={webStyle.divider} />}
                            </React.Fragment>
                        ))}
                        <Box className={classes.actionButtonWrapper} >
                            <CustomStyledButton
                                isSecondary
                                data-test-id="deactivate"
                                onClick={this.navigateFromSettings('deactivate')}
                                label={configJSON.ButtonLabel.DeactivateAccount}
                            />
                            <CustomStyledButton
                                data-test-id="logout"
                                onClick={this.handleLogout}
                                label={configJSON.ButtonLabel.Logout}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    }
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { address, city, district, postalCode } = this.state;
        const addressComponents = [address, city, district, postalCode];
        const formattedAddress = addressComponents.filter(component => component).join(', ');
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    {!this.state.showHealthDetails ? (
                        <Box className={classes.leftWrapper}>
                            <SidebarNotificationWrapper hideNotification {...this.props}>
                                <Box className={classes.rightWrapper}>
                                    <Box className={classes.root}>
                                        <Box>
                                            <p className={classes.profileHeading}>
                                                Profile
                                            </p>
                                        </Box>
                                        <Box>
                                        <Box style={{display:'flex',justifyContent:'space-between',marginBottom:'35px',marginTop:'30px'}}>
                                            <Box>
                                                <Box className={classes.topPatientLeftContainer}>
                                                    <Box style={{width:'auto'}} className={classes.profileContainer}>
                                                        <img
                                                            className={classes.avatar}
                                                            src={this.state.profilePicture}
                                                        />
                                                    </Box>
                                                    <Box style={{paddingLeft:0}} className={classes.profileDetailsContainer}>
                                                        <p className={classes.userName}>
                                                            {this.state.user_name}
                                                        </p>
                                                        <p className={classes.userPhoneNumber}>
                                                            {this.state.city} | +91{this.state.phoneNumber}
                                                        </p>
                                                    </Box>
                                                </Box>
                                                
                                            </Box>
                                            <Box>
                                                <Box className={classes.topRightContainer}>
                                                    <Box>
                                                        <img
                                                            alt="SOS"
                                                            className={classes.sosImage}
                                                            src={SOSImage}
                                                        />
                                                    </Box>
                                                    <div className={classes.hiddenDown} >
                                                        <IconButton data-test-id="editProfile" onClick={() => this.openEditProfiles()}>
                                                        <img
                                                            className={classes.editImage}
                                                            src={Edit}
                                                        />
                                                        </IconButton>
                                                    </div>
                                                    <div className={classes.hiddenDown} >
                                                        <IconButton onClick={() => this.goToBlock('Dashboard')}>
                                                        <img
                                                            className={classes.notificationImage}
                                                            src={Notification}
                                                        />
                                                        </IconButton>
                                                    </div>
                                                    <Box>
                                                        <IconButton data-test-id="open-settings" onClick={this.handleOpenSettings} style={this.state.settingPopoverEl ? webStyle.activeIcon : webStyle.settingIcon}>
                                                            <img className={classes.settingImage} src={Setting} />
                                                        </IconButton>
                                                    </Box>
                                                    
                                                </Box>
                                            </Box>
                                            </Box>
                                            <Box>
                                            <Box className={classes.boxTopBorder} />
                                                <Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsLabel}>
                                                            Age
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetails}>
                                                            {this.state.userAge} y/o
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsLabel}>
                                                            Gender
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                            {this.state.gender}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsLabel}>
                                                            Languages Known
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                            {this.state.languages}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <p className={classes.userDetailsLabel}>
                                                            Email Address
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetails}>
                                                            {this.state.email}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.addressSubConatiner}>
                                                        <p className={classes.userDetailsLabel}>
                                                            Address
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetailsCaps}>
                                                            {formattedAddress}
                                                        </p>
                                                    </Box>
                                                    <Box className={classes.boxTopBorder} />
                                                    <Box className={classes.healthDetailsNameSubContainer}>
                                                        <p className={classes.userDetailsLabel}>
                                                            Health Details
                                                        </p>
                                                        <p className={classes.userDetailsColon}>
                                                            :
                                                        </p>
                                                        <p className={classes.userDetails}>
                                                            The symptoms and full details of the condition of the patient
                                                        </p>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Button
                                                        data-test-id="HealthDetails"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.healthDetailsButtons}
                                                        onClick={this.handleEditPatientHealthDetail}
                                                    >
                                                        Health Details
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {this.renderPopover()}
                                </Box>
                            </SidebarNotificationWrapper>
                        </Box>
                    ) : (
                        <Box>
                            {this.callHealthDetails}
                        </Box>
                    )}
                    <Loader loading={this.state.isLoading} />
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableViewUserProfile);

const webStyle = {
    notificationWrapper: {
        minWidth: '340px'
    },
    activeIcon: {
        padding: '8px',
        backgroundColor: 'white',
        borderRadius: '10px',
        width: '40px',
        zIndex: 200,
        height: '40px',
        top:'30%'
    } as React.CSSProperties,
    settingIcon: {
        borderRadius: '8px',
        zIndex: 200,
        width: '40px',
        height: '40px',
        top:'30%'
    } as React.CSSProperties,
    filterModalWrapper: {
        position: "absolute",
        zIndex: 200,
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: '10px',
    } as React.CSSProperties,
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        zIndex: 100
    } as React.CSSProperties,
    paper: {
        borderRadius: '25px'
    },
    backDrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    divider: {
        backgroundColor: "#F0F0F0"
    },
    
    settingsItem: {
        fontSize: '20px',
        color: '#292929',
        fontFamily: "SF Pro Text Regular",
        lineHeight: '64px',
        textAlign: 'center',
        cursor: 'pointer'
    } as React.CSSProperties,
    settingsOuterWrapper: {
        width: '390px',
        background: 'white'
    },

    settingsInnerWrapper: {
        border: '1px solid #EDEDED',
        padding: '32px 24px',
        borderRadius: '25px',
        boxShadow: "0px 2px 24px 0px #2C2C2C0A"
    },

    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '24px',
        marginTop: '42px'
    } as React.CSSProperties,

}
// Customizable Area End