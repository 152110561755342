import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";
import {
  withStyles,
  Typography,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { FlexDirectionProperty, OverflowXProperty, PositionValue, textTransform } from "./types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { Calender, UserProfile } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import {formatUTC } from "../../../components/src/utils";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});
// Customizable Area End
export class Reservations2ViewDoctorBooking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    try {
      await Promise.all([this.getAvaibilityList(), this.getPatientProfileApi()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
  }

  renderSlotStyleObj = (slot: { start: string, end: string, halfBooked?: boolean }) => {
    let style = {};
  
    if (slot.start === this.state.selectedSlot.start || slot.end === this.state.selectedSlot.end) {
      style = { ...webStyles.selected, background: 'rgb(55, 128, 224)' };
    } else if (slot.halfBooked) {
      style = { ...webStyles.selected, background: 'linear-gradient(to right, rgb(55, 128, 224) 50%, rgb(211, 227, 248) 50%)' };
    } else {
      style = webStyles.unSelected;
    }
    return style;
  };

  TimeSlot = (
    title: "Morning" | "Evening",
    duration: string,
    slots: {start:string,end:string, halfBooked?:boolean}[],
    index: number
  ) => {
    let id :number|null;
    if(title === "Morning"){
        id = this.state.morningAvailabilitySlotId
    }
    else {
        id = this.state.eveningAvailabilitySlotId
    }
    const {classes} = this.props;
    return (
      <Box style={webStyles.availabilitySlotContainer}>
        <Box style={{ width: "25%", maxWidth: "150px", marginTop: "12px" }}>
          <Typography variant="h4" className={classes.weekDays}>
            {title}
          </Typography>
          <Typography variant="h4" className={classes.slotDuration}>
            {duration}
          </Typography>
        </Box>
        <Box
          key={index}
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            columnGap:'20px',
            rowGap:'20px',
            paddingTop:'14px',
            justifyContent: "flex-start",
          }}
        >
          {slots.map((slot)=> {
            return(
              <Tooltip title={!this.state.isHPOnLeave ? "" :"Doctor is on leave today"} arrow  >
                <div>
                <Button
                  data-test-id='slot-btn'
                  style={this.state.isHPOnLeave ? webStyles.slotClass: this.renderSlotStyleObj(slot)}
                  disabled={this.state.isHPOnLeave}
                  onClick={() => this.handleSelectSlot(slot, String(id), title)}
                >{formatUTC(slot.start,"h:mm")} - {formatUTC(slot.end,"h:mm A")}
                </Button>
                </div>
              </Tooltip>
            )
          })}
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const totalWidth = 60;
    const height = 14;
    const selectedWidth = (50 / 100) * totalWidth;
    const unSelectedWidth = totalWidth - selectedWidth;
    const details = [
      { label: "Service", value: this.state.summaryData?.attributes?.service },
      {
        label: "Matching Languages",
        value: this.state.summaryData?.attributes?.matching_languages?.join(", "),
      },
      { label: "Patient Name", value: this.state.patientName },
      {
        label: "Date",
        value: this.formatSummaryDate(this.state.summaryData?.attributes?.date),
      },
      {
        label: "Appointment Time",
        value: `${this.state.period}, ${this.state.summaryData?.attributes?.start_time}`.toLowerCase(),
      },
      { label: "Problem", value: this.state.summaryData?.attributes?.problem},
      {
        label: "Consultation Fee",
        value: `${this.state.sign}${this.state.summaryData?.attributes?.healthcare_personnel_fee+50 || "0"}`,
      },
    ];
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box style={webStyles.bookDoctorProfileContainer}>
        <SidebarNotificationWrapper {...this.props}>
          <style>
            {`
              .react-datepicker-wrapper {
                width: 100%;
              }
            `}
          </style>
          <Box className={classes.pageWrapper}>
            <>
              <Box style={webStyles.bookDoctorContainer}>
                <Box style={webStyles.bookDoctorHeaderContainer}>
                  <Box style={webStyles.bookDoctorHeading}>
                    <ArrowBackIosIcon
                      style={webStyles.bookDoctorBackButton}
                      data-test-id="bookDoctorBackBtn"
                      onClick={this.navigateToDoctorAvaibility}
                    />
                    <Typography variant="h6" className={classes.bookDoctorTitle}>
                      Book Now
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Grid container>
                <Grid item xl={4} md={5} sm={6} xs={12}>
                  <Box className={classes.boxContainer}>
                    <Typography variant="h4" className={classes.registerHospitalBookingLabel}>
                      Patient Name<span className={classes.requiredField}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      data-test-id="patientName"
                      className={classes.doctorBookingRegisterInputFields}
                      placeholder="Enter your name"
                      value={this.state.patientName}
                      onChange={(event) => this.handlePatientName(event)}
                      />
                  </Box>
                </Grid>
                <Grid item xl={4} md={5} sm={6} xs={12}>
                  <Box className={classes.boxContainer}>
                    <Typography variant="h6" className={classes.registerDateLabel}>
                      Set Date<span className={classes.requiredField}>*</span>
                    </Typography>
                    <Box className={classes.customDatePickerName} style={{ marginBottom: (this.state.errors?.date) ? "13px" : "20px" }}>
                      <span className={this.state.errors?.date ? classes.customDateInputNameError : classes.customDateInputName}>
                        <DatePicker
                          popperPlacement="bottom-start"
                          data-test-id="bookingdate"
                          popperModifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [60, 0],
                              },
                            },
                            {
                              name: "preventOverflow",
                              options: {
                                tether: false,
                                rootBoundary: "viewport",
                                altAxis: true,
                              },
                            },
                          ]}
                          wrapperClassName={classes.datepickerClassFullWidth}
                          calendarClassName={classes.datepickerClass}
                          onChange={this.handleBookingDateChange}
                          className={(this.state.errors?.date) ? classes.dateFieldError : classes.dateField}
                          dateFormat="Select date"
                          value={this.formatCustomDate(this.state.bookingDate)}
                          placeholderText="Select date"
                          minDate={new Date()}
                          open={this.state.isCalendarOpen}
                          onKeyDown={this.handleKeydownEsc}
                          onInputClick={this.handleDateFieldClick}
                          selected={this.state.bookingDate}
                        />
                      </span>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{marginTop:'20px', marginBottom:'20px'}} >
                <Grid item xl={4} md={5} sm={12} xs={12}>
                  <Box className={classes.availableTimes}>Available Time<span style={{ fontFamily: "SF Pro Text Medium", fontSize: "24px", color: "#FF0000" }}>*</span></Box>
                </Grid>
                <Grid item xl={4} md={5} sm={12} xs={12} style={{ alignContent: "center" }}>
                  <Box className={classes.slotContainer}>
                    <Box style={{ display: "flex", gap: "4px" }}>
                      <Box
                        style={{
                          display: "flex",
                          width: `${totalWidth}px`,
                          height: `${height}px`,
                          border: "1px solid #808080",
                          borderRadius: "4px"
                        }}
                      ></Box>
                      <Box className={classes.slotText}>- Full Free Slot</Box>
                    </Box>
                    <Box style={{ display: "flex", gap: "4px" }}>
                      <Box
                        style={{
                          display: "flex",
                          width: `${totalWidth}px`,
                          height: `${height}px`,
                          border: "none",
                        }}
                      >
                        <Box
                          style={{
                            width: `${selectedWidth}px`,
                            height: "100%",
                            backgroundColor: "#3780E0",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px"
                          }}
                        />
                        <Box
                          style={{
                            width: `${unSelectedWidth}px`,
                            height: "100%",
                            backgroundColor: "#d3e3f8",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px"
                          }}
                        />
                      </Box>
                      <Box className={classes.slotText}>- Half Booked</Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box style={webStyles.slotBookingContainer}>
                <Box style={{width:'100%'}} className={classes.availabilityAllSlotContainer}>
                  <>
                    {this.state.firstSlotTime.length > 0 &&
                      (
                        <>
                          {this.TimeSlot("Morning", `${this.handelRemoveSpace(this.state.firstStartDate)} - ${this.handelRemoveSpace(this.state.secondStartDate)}`, this.state.firstSlotTime, 0)}
                        </>
                      )}
                    {this.state.secondSlotTime.length > 0 &&
                      (
                        <>
                          {this.TimeSlot("Evening", `${this.handelRemoveSpace(this.state.firstEndDate)} - ${this.handelRemoveSpace(this.state.secondEndDate)}`, this.state.secondSlotTime, 1)}
                        </>
                      )}
                  </>
                  <Box style={{ marginTop: "35px" }}>
                    <Typography variant="h4" className={classes.weekDays}>
                      Write the problem
                    </Typography>
                    <TextField
                      variant="outlined"
                      data-test-id="problem"
                      className={classes.doctorBookingRegisterTextFields}
                      placeholder="Write here..."
                      value={this.state.text}
                      onChange={(e) => {this.settext(e)}}
                      multiline rows={8}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      data-test-id="showDoctorSummaryPopup"
                      style={webStyles.appointmentBookBtn}
                      onClick={this.bookDoctor}
                    >
                      Book Appointment
                    </Button>
                  </Box>
                </Box>
              </Box>
              {this.state.showDialog && (
                <Box
                  className={classes.PaperDoctorSummaryPropsClass}
                  data-test-id="showSummary"
                >
                  <Box className={classes.popoverDoctorSummaryContainer}>
                    <Box className={classes.selectSummaryDoctorContainer}>
                      <Typography variant="h6" className={classes.titleDoctorHeading}>
                        Summary
                      </Typography>
                      <Typography variant="h6" data-test-id="closeSummary" className={classes.closeInsuranceIconBtn} onClick={this.showDoctorSummaryPopup}>
                        <CloseIcon />
                      </Typography>
                    </Box>
                    <Box style={{ maxHeight:'calc(65vh - 100px)',overflowY: 'scroll', scrollbarColor:"#d9d5d5 #fff", scrollbarWidth: "thin", marginRight: "5px" }}>
                      <Box className={classes.topContainer} data-test-id="summaryContainer">
                        <Box className={classes.profileDoctorImageContainer}>
                          <img
                            data-test-id="avatarSize"
                            className={classes.summaryDoctorProfile}
                            src={UserProfile}
                          />
                        </Box>
                        <Box className={classes.profileDoctorDetailContainer} data-test-id="summaryDetails">
                          <Typography variant="h6" className={classes.userDoctorName}>
                            Dr. {this.state.summaryData.attributes?.healthcare_personnel_name}
                          </Typography>
                          <Typography variant="h6" className={classes.headingAddress}>
                            {this.state.summaryData.attributes?.city}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.bottomBorder} />
                      <Box className={classes.summaryModalContentWrapper}>
                          {details.map((element)=>{
                            return <Box style={{width:'100%',display:"flex"}}>
                            <Typography variant="h6" style={{width:'100%'}} className={classes.summaryDoctorTitle}>{element.label}</Typography>
                            <Typography variant="h6" style={webStyles.colon}>:</Typography>
                            <Typography variant="h6" style={{width:'100%'}} className={classes.summaryDoctorDetails}>{element.value}</Typography>
                          </Box>
                          })}
                      </Box>
                    </Box>
                    <Box>
                      <Button data-test-id="changeDetails" variant="contained" color="primary" className={classes.changeDoctorDetailsButtons} style={{ textTransform: "capitalize" }} onClick={this.changeDocSummaryDetails}>
                        Change Details
                      </Button>
                      <Button data-test-id="makeDocPaymentBtn" variant="contained" color="primary" className={classes.paymentDoctorButtons} style={{ textTransform: "capitalize" }} onClick={this.handleMedicPayment}>
                        Select Payment
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              {this.state.bookingErrorMessage &&<Dialog classes={{ paper: classes.dialogpapers }} style={{ textAlign: "center" }} id="errorBox" disableScrollLock={true} open={!!this.state.bookingErrorMessage}>
                <DialogContent><p className={classes.dialogTexts}> {this.state.bookingErrorMessage}</p></DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Button color="primary" onClick={() => this.handleDialogClose()} variant="contained" className={classes.dialogButtons} style={{ textTransform: "capitalize" }} data-test-id="closePopUp">
                    Okay
                  </Button>
                </DialogActions>
              </Dialog>}
            </>
          </Box>
          </SidebarNotificationWrapper>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  headingAddress:{
    '@media(max-width:600px)':{
    fontSize:'16px'
    }
  },
  availableTimes:{
     fontFamily: "SF Pro Text Medium", 
     fontSize: "24px", 
     color: "#292929" ,
     '@media(max-width:600px)':{
      fontSize: "18px",
     }
  },
  pageWrapper:{
    padding:'48px 60px',
    '@media(max-width:600px)':{
      padding:'24px'
    }
  },
  slotClass:{ 
    backgroundColor:'rgba(0, 0, 0, 0.26)', 
    cursor : 'not-allowed',
    borderRadius: "8px",
    height: "50px",
    width:'163px',
    boxSizing:'border-box' as const,
    color: "#292929",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular",
    padding: "16px 12px",
    border: "1px solid #808080",
    fontSize: "16px",
    textTransform: "lowercase" as "lowercase"
  },
  summaryModalContentWrapper:{
     display: "flex", 
     justifyContent: "space-between",
     flexDirection:'column' as const,
     gap:"20px"
  },
  colon:{
    margin:'0 26px',
    color: "#808080",
    fontSize: "18px",
    minHeight:'28px',
    fontFamily: "SF Pro Text Regular",
  },
  bookDoctorProfileContainer: {
    display: "flex",
    overflowX:"hidden" as "hidden",
    '@media (max-width:1100px)': {
      overflowX:"scroll" as "scroll !important",
    },
  },
  bookDoctorContainer: {
    marginBottom: "20px",
    width: "70%",
    minHeight: "10px",
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: '20px',
  },
  slotBookingContainer: {
    marginTop: "0px",
    marginBottom:'30px',
    width: "100%",
    minHeight: "10px",
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: '20px',
  },
  bookDoctorHeaderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
    justifyContent: "space-between",
  },
  bookDoctorHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "25px",
  },
  bookDoctorBackButton: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
    padding: "12px 8px 12px 16px",
  },
  bookDoctorTitle: {
    fontFamily: "SF Pro Text Bold",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "33.41px",
    color: "#292929",
    '@media(max-width:600px)':{
      fontSize:'18px'
    }
  },
  doctorBookingRegisterInputFields: {
    marginBottom: "10px",
    width: "100%",
    fontSize: "18px",
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    '&::placeholder': {
      fontSize: "18px",
      opacity: 1,
      color: '#808080 !important',
    },
    "& .MuiInputBase-input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      fontSize: "18px",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular",
    },
    "& ::-webkit-input-placeholder": {
      color: "#808080",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        color: "#808080",
      }
    }
  },
  requiredField: {
    color: "#f44336"
  },
  registerHospitalBookingLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    margin: "10px 0px",
    textAlign: "left" as "left",
    caretColor: "transparent",
    color: "#292929",
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  registerDateLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    margin: "4px 0px 10px",
    textAlign: "left" as "left",
    caretColor: "transparent",
    color: "#292929",
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  boxContainer: {
    width: "94%"
  },
  customDateInputName: {
    width: "100%",
    borderRadius: "8px",
    "& input": {
      color: "#292929",
      fontSize: "18px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent",
      overflow:'hidden',
      textOverflow:'ellipsis'
    },
    "& ::-webkit-input-placeholder": {
      color: "#565454",
    },
    "&:focus": {
      border: "1px solid blue",
    },
  },
  customDateInputNameError: {
    width: "100%",
    "& input": {
      color: "#292929",
      borderRadius: "8px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid red",
      fontSize: "18px",
      display: "flex",
      margin: "auto",
      caretColor: "transparent",
      fontFamily: "SF Pro Text Regular",
      overflow:'hidden',
      textOverflow:'ellipsis'
    },
    '&::placeholder': {
      opacity: 1,
      color: '#808080 !important',
    },
    '&::-webkit-input-placeholder': {
      opacity: 1,
      color: '#808080 !important',
    },
    "&:focus": {
      border: "1px solid blue",
    },
    borderRadius: "8px"
  },
  customDateFieldName: {
    padding: "15px 60px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
  },
  datepickerClass: {
    "& .react-datetime-picker__wrapper": {
      border: "none",
    },
    "& .react-datepickerClass-wrapper": {
      display: "flex !important"
    },
    "& .react-datepickerClass__month": {
      margin: "0px !important",
    },
    "& .react-datepickerClass-popper": {
      transform: "translate(90px, 209px) !important",
    },
    "& .react-datepickerClass__day": {
      width: "1.3rem !important",
      height: "1rem !important",
    },
    "& .react-datepickerClass__day-name": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__month-container": {
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day-names": {
      marginBottom: "-1px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__navigation": {
      width: "22px !important",
      height: "22px !important",
      margin: "5px -2px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--keyboard-selected": {
      backgroundColor: "white !important",
      color: "#000 !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--today": {
      color: "#1d5d90 !important",
      fontWeight: "bold !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day:hover": {
      color: "#ccc !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
  },
  dateFieldError: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    padding: "15px 0px 15px 60px",
    display: "flex",
    justifyContent: "center",
    border: "1px solid red",
    cursor: "pointer",
    borderRadius: "8px",
    "&:focus-visible": {
      border: "2px solid #3a52d4",
      outline: "none",
    },
    "&:focus": {
      border: "2px solid #3a52d4",
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #3f51b5",
      outline: "none",
    },
    color: "#292929",
    "& input": {
      color: "#292929"
    }
  },
  dateField: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    padding: "15px 0px 15px 60px",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #3f51b5",
    cursor: "pointer",
    borderRadius: "8px",
    "&:focus-visible": {
      border: "2px solid #3a52d4",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:hover": {
      outline: "none",
      border: "1px solid #3f51b5",
    },
    color: "#292929",
    "& input": {
      color: "#292929"
    }
  },
  slotContainer: {
    gap: "20px",
    display: "flex",
    width: "94%",
    justifyContent: "flex-end",
    marinTop: "6px",
    '@media(max-width:991px)':{
      justifyContent:"start"
    }
  },
  slotText: {
    fontSize: "13px",
    fontFamily: "SF Pro Text Medium",
    color: "#808080"
  },
  unSelected: {
    width:'163px',
    height: "50px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    color: "#292929",
    fontFamily: "SF Pro Text Regular",
    border: "1px solid #808080",
    padding: "16px 12px",
    cursor: "pointer",
    fontSize: "16px",
    caretColor: "transparent",
    textTransform: "lowercase " as "lowercase",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#292929"
    }
  },
  selected: {
    height: "50px",
    width:'163px',
    borderRadius: "8px",
    backgroundColor: "#3780E0",
    color: "#FFFFFF",
    fontFamily: "SF Pro Text Regular",
    border: "none",
    padding: "16px 12px",
    cursor: "pointer",
    fontSize: "16px",
    caretColor: "transparent",
    textTransform: "lowercase " as "lowercase",
    "&:hover": {
      backgroundColor: "#3780E0",
      color: "#FFFFFF",
    }
  },
  availabilitySlotContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:'6px',
    gap:'30px'
  },
  weekDays: {
    color: "#292929",
    fontSize: "20px",
    fontFamily: "SF Pro Text Medium",
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  slotDuration: {
    color: "#808080",
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
  },
  doctorBookingRegisterTextFields: {
    width: "75%",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "15px",
    '&::-webkit-input-placeholder': {
      opacity: 1,
      color: '#808080 !important',
    },
    '&::placeholder': {
      color: '#808080 !important',
      fontSize: "18px",
      opacity: 1,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiInputBase-input": {
      fontSize: "18px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      cursor: "pointer",
      borderRadius: "8px",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        fontFamily: "SF Pro Text Regular",
        color: "#808080",
        opacity: 1,
        fontSize: "18px",
      }
    },
    "& ::-webkit-input-placeholder": {
      color: "#808080",
    },
    '@media(max-width:991px)':{
      width:'100%'
    },
    '@media(max-width:600px)':{
      '&::placeholder': {
        fontSize:'16px'
      },
      "& .MuiInputBase-input": {
      fontSize: "16px",
    },
    }
  },
  appointmentBookBtn: {
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    width: "250px",
    height: "52px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    marginRight: "20px",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    '@media (max-width:1250px)': {
      width: "230px !important",
    },
  },
  PaperDoctorSummaryPropsClass: {
    backgroundColor: '#050505a3',
    position: "fixed" as PositionValue,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
    overflowY: "auto" as OverflowXProperty,
    '@media(max-width:991px)':{
      marginLeft:0
    }
  },
  popoverDoctorSummaryContainer: {
    position: 'relative' as PositionValue,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '18px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    width: '430px',
    padding: '20px 30px',
    maxHeight: "calc(100vh - 100px)",
    '@media (max-height: 1000px)': {
      top: '50%',
    },
    '@media(max-width:600px)':{
      width:'90%',
      boxSizing:"border-box",
      left:'50%'
    }
  },
  titleDoctorHeading: {
    display: "flex",
    fontSize: "28px",
    marginTop: "15px",
    alignItems: "start",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    justifyContent: "space-between",
    '@media(max-width:600px)':{
      fontSize:'20px'
    }
  },
  selectSummaryDoctorContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "433px",
    '@media(max-width:600px)':{
      width:'100%'
    }
  },
  summaryDoctorProfile: {
    borderRadius: "50%",
    width: "138px",
    height: "138px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
    '@media(max-width:600px)':{
      height:'90px',
      width:'90px'
    }
  },
  topContainer: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    alignItems: "center",
    padding: "0px 0px 30px 0px",
    marginTop: "25px",
    gap: "24px"
  },
  userDoctorName: {
    fontSize: "24px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    lineHeight: "28px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    width: "100%",
    maxHeight: "55px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    '@media(max-width:600px)':{
      fontSize:'18px'
    }
  } as any,
  summaryDoctorTitle: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    caretColor: "transparent",
    color: "#292929",
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap' as const,
    textTransform :"capitalize" as const,
    '&:hover':{
      overflow:'visible',
      wordBreak:'break-word',
      whiteSpace:'normal' as const
    },
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  summaryDoctorDetails: {
    fontSize: "18px",
    minHeight:'28px',
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080",
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap' as const,
    textTransform :"capitalize" as const,
    '&:hover':{
      overflow:'visible',
      wordBreak:'break-word',
      whiteSpace:'normal' as const
    },
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  paymentDoctorButtons: {
    width: "300",
    height: "56px",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    display: "flex",
    margin: "25px auto 25px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
    '@media(max-width:600px)':{
      width:'100%',
      height:"auto",
      padding:'10px',
      fontSize:'16px'
    }
  },
  changeDoctorDetailsButtons: {
    width: "300",
    height: "56px",
    fontSize: "18px",
    backgroundColor: "#fff",
    color: "#014866",
    border: "1px solid #014866",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    margin: "30px auto 0px",
    display: "flex",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#f014866",
    },
    '@media(max-width:600px)':{
      width:'100%',
      height:"auto",
      padding:'10px',
      fontSize:'16px'
    }
  },
  profileDoctorImageContainer: {
   
  },
  profileDoctorDetailContainer: {
    // marginTop: "20px",
  },
  dialogpapers: {
    borderRadius: "20px",
    maxWidth: "450px",
    padding: "10px 40px 50px",
    marginLeft: "13vw",
    minWidth:"350px",
    '@media(max-width:600px)':{
      marginLeft:'32px',
      minWidth:'auto'
    }
  },
  dialogTexts: {
    fontSize: "20px",
    color: "#383838",
    fontFamily: "SF Pro Text Medium",
    fontWeight: 500,
    lineHeight: "26px",
    caretColor: "transparent !important",
    '@media(max-width:600px)':{
      fontSize:'16px'
    }
  },
  dialogButtons: {
    width: "100%",
    height: "65px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontWeight: 600,
    fontSize: "18px",
    margin: "20px auto 10px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    caretColor: "transparent",
    '@media(max-width:600px)':{
      fontSize:'16px',
      height:'auto'
    }
  },
}

export default withStyles(webStyles)(Reservations2ViewDoctorBooking);
// Customizable Area End

