import {
  Box,
  ListItemIcon,
  ListItem,
  List,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  Bed,
  BedGray,
  Notification,
  NotificationGray,
  User,
  UserGray,
  Note,
  NoteGray,
  Home,
  HomeGray,
  HomeCure,
  PersonalCard,
  PersonalCardGray,
  Calendar,
  CalendarActive
} from "./assets";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

interface Props {
  expandMenu?: boolean;
  toggleSidebar?: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  asidemenu: {
    position: 'fixed',
    background: "#014866",
    height: "100vh",
    overflowY: 'auto',
    top: "0px",
    left: "0px",
    zIndex: 1000,
    width: "132px",
    caretColor: "transparent",
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin",
  },
  expandedMenu: {
    position: 'fixed',
    background: "#014866",
    height: "100vh",
    zIndex: 1000,
    top: "0px",
    left: "0px",
    overflowY: 'auto',
    minWidth: "132px",
    caretColor: "transparent",
    width: '268px',
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin",
  },
  logo: {
    width: "100px",
    height: "90px",
    [theme.breakpoints.down("md")]: {
      width: "90px",
      height: "60px",
    },
    display: "flex",
    justifyContent: "center",
    '@media (max-width: 1400px)': {
      width: "95px",
      height: "85px",
    },
    '@media (max-width: 1300px)': {
      width: "90px",
      height: "80px",
    },
  },
  listul: {
    height: "auto",
    display: 'flex',
    flexDirection: 'column',
    gap: '50px'
  },
  listitem: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: 500,
    letterSpacing: "0.16px",
    lineHeight: "28px",
    height: '47px',
    paddingLeft: "0px !important",
    paddingRight: '0'
  },
  active: {
    borderRadius: '0 10px 10px 0',
    color: '#2B3D7D',
    fontSize: "16px",
    height: '47px',
    borderLeft: '2px solid white',
    fontFamily: "poppins",
    fontWeight: 500,
    letterSpacing: "0.16px",
    lineHeight: "28px",
    paddingLeft: "0px !important",
    paddingRight: '0'
  },
  activeIcon: {
    color: "#2B3D7D",
    minWidth: "40px",
    cursor: "none",
    width: '100%',
    display: 'flex',
    gap: '26px'
  },
  listicon: {
    color: "#fff",
    minWidth: "40px",
    width: '100%',
    gap: '26px',
    cursor: "none",
  },
  link: {
    textDecoration: "none",
  },
  listText: {
    fontSize: '16px',
    fontFamily: "'Poppins' !important",
    fontWeight: 500,
    letterSpacing: '0.16px',
    lineHeight: '28px',
    margin: '4px 0'
  },
  imageSize: {
    height: "30px",
    cursor: "pointer",
    margin: '0 auto'
  },
  activeImage: {
    height: "30px",
    cursor: "pointer",
    marginLeft: '47px'
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    gap: '20px',
    padding: "40px 0px 30px",
  },
  border: {
    width: "20px",
    height: "20px",
    color: "#fff"
  },
  activeTabName: {
    fontSize: '20px',
    fontFamily: 'SF Pro Text Semibold',
    color: 'white',
    '&:hover':{
      overflow:'auto',
      whiteSpace:'normal',
    }
  },
  tabName: {
    fontSize: '20px',
    fontFamily: 'SF Pro Text Medium',
    color: 'AEAEAE',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace:'nowrap'
  }
}));

const Sidebar = ({ expandMenu, toggleSidebar }: Props) => {
  const classes = useStyles();
  const [role, setRole] = React.useState<any>(window.localStorage.getItem("userType"));
  const currentLocation = window.location.pathname.split("/");
  const currentPath = "/" + currentLocation[1];
  const [activeTab, setActiveTab] = React.useState(currentPath);
  const hpUserType = (localStorage.getItem("hpUserRole"))?.toLowerCase();
  const tabs =
    role === "patient"
      ? [
        { name: "Home", icon: HomeGray, activeIcon: Home, path: activeTab === "/PatientHospitaltBooking" ? "/PatientHospitaltBooking" : "/Dashboard" },
        { name: "Manage", icon: BedGray, activeIcon: Bed, path: "#" },
        { name: "Booking", icon: NoteGray, activeIcon: Note, path: "/PatientOrderManagement" },
        { name: "Profile", icon: UserGray, activeIcon: User, path: activeTab === "/EditProfile" ? "/EditProfile" : "/Profile" },
      ]
      : role === "hospital"
        ? [
          { name: "Home", icon: HomeGray, activeIcon: Home, path: "/Dashboard" },
          { name: "Manage", icon: PersonalCardGray, activeIcon: PersonalCard, path: "#" },
          { name: "Booking", icon: NoteGray, activeIcon: Note, path: "/manage-hospital-reservation" },
          { name: "Profile", icon: UserGray, activeIcon: User, path: activeTab === "/UpdateHospitalProfile" ? "/UpdateHospitalProfile" : "/HospitalProfile" },
        ] :
        [
          { name: "Home", icon: HomeGray, activeIcon: Home, path: "/Dashboard" },
          { name: "Booking", icon: NoteGray, activeIcon: Note,  path: hpUserType === "ayah" || hpUserType === "nurse" ? "#" : "/HpBooking" },
          { name: "Profile", icon: UserGray, activeIcon: User, path: activeTab === "/UpdateHealthcarePersonneProfile" ? "/UpdateHealthcarePersonneProfile" : "/HealthcarePersonneProfile" },
          { name: "Calendar", icon: Calendar, activeIcon: CalendarActive, path: hpUserType === "ayah" || hpUserType === "nurse" ? "/Scheduling" : "/SchedulingCalendar" },
        ];

  React.useEffect(() => {
    setRole(window.localStorage.getItem("userType"));
  }, []);

  return (
    <Box className={expandMenu ? classes.expandedMenu : classes.asidemenu}>
      <Box className={classes.logoContainer}>
        <img src={HomeCure} className={classes.logo} alt="Logo" />
        {expandMenu && <Typography style={webStyle.homecure} >HomeCure</Typography>}
      </Box>
      {!expandMenu && <Divider style={webStyle.divider} />}
      <Box style={{ padding: "32px 0px" }}>
        <List className={classes.listul}>
          {tabs.map((tab: any) => (
            <Link className={classes.link} to={tab.path} key={tab.name}>
              <ListItem
                key={tab.name}
                button
                style={expandMenu && activeTab === tab.path ? webStyle.activeTab : {}}
                className={activeTab === tab.path ? classes.active : classes.listitem}
                onClick={() => {
                  if (tab.path !== "#") {
                    if (activeTab === tab.path) {
                      if (activeTab === "/EditProfile") {
                        setActiveTab("/EditProfile")
                        window.location.href = "/Profile"
                      } else if (activeTab === "/UpdateHospitalProfile") {
                        setActiveTab("/UpdateHospitalProfile")
                        window.location.href = "/HospitalProfile"
                      } else if (activeTab === "/UpdateHealthcarePersonneProfile") {
                        setActiveTab("/UpdateHealthcarePersonneProfile")
                        window.location.href = "/HealthcarePersonneProfile"
                      } else if (activeTab === "/PatientHospitaltBooking") {
                        setActiveTab("/PatientHospitaltBooking")
                        window.location.href = "/Dashboard"
                      } else if (activeTab === "/Dashboard") {
                        window.location.reload()
                      } else {
                        setActiveTab(tab.path);
                      }
                    }
                  }
                }}
              >
                <ListItemIcon
                  className={activeTab === tab.path ? classes.activeIcon : classes.listicon}
                >
                  <img
                    src={tab.path !== "#" && activeTab === tab.path ? tab.activeIcon : tab.icon}
                    className={expandMenu ? classes.activeImage : classes.imageSize}
                    alt={tab.name}
                  />
                  {expandMenu && <Typography className={activeTab === tab.path ? classes.activeTabName : classes.tabName}>{tab.name}</Typography>}
                </ListItemIcon>
              </ListItem>
            </Link>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton style={webStyle.toggleButton} onClick={() => toggleSidebar && toggleSidebar(expandMenu!)} >
              {!expandMenu ? <KeyboardArrowRightRoundedIcon /> : <ChevronLeftRoundedIcon />}
            </IconButton>
          </Box>
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;

const webStyle = {
  divider: {
    height: '2px',
    width: "61px",
    backgroundColor: 'white',
    margin: '0 auto'
  },
  homecure: {
    fontFamily: 'SF Pro Text Heavy',
    fontSize: '18px',
    color: 'white'
  },
  activeTab: {
    background: "linear-gradient(115.07deg, #FFFFFF -36.15%, rgba(255, 255, 255, 0.0001) 90.95%)"
  },
  toggleButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    height: '40px',
    width: '40px',
    color: '#000000'
  }
}