import { Theme } from "@material-ui/core";
import { ScrollbarType } from "../types";
 // Customizable Area Start
 // Customizable Area End

export const styles = (theme: Theme) => ({
   // Customizable Area Start
   dialogPaper: {
    maxWidth: "550px",
    width:'100%',
    borderRadius: "10px",
    '@media(max-width:600px)':{
      width:'90%'
    }
  },
  dialogPrescriptionPaper: {
    minWidth: "450px",
    borderRadius: "20px",
    '@media(max-width:600px)':{
      minWidth:"90%"
    }
  },
  docCover:{
    width:"450px",
    display:"flex",
    minHeight:"300px",
    maxHeight:"400px",
    margin:"15px auto",
    objectFit: "contain",
    '@media(max-width:600px)':{
      width:'auto'
    }
  } as any,
  dialogPrescriptionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  presBackButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    caretColor:"transparent",
    margin: "35px",
    borderRadius: "5px",
    "& .MuiSvgIcon-root":{
      marginLeft:"10px"
    },
    '@media(max-width:600px)':{
      margin:'35px 20px'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "5px 30px 10px",
    '@media(max-width:600px)':{
      justifyContent: 'center',
    }
  },
  title: {
    width:"100%",
    display:"flex",
    justifyContent:"center",
    fontWeight: 600,
    caretColor:"transparent",
    fontSize: "18px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    paddingLeft: "40px",
    '@media(max-width:600px)':{
      fontSize:'16px',
      paddingLeft:0
    }
  },
  docTitle: {
    width:"100%",
    display:"flex",
    justifyContent:"center",
    fontWeight: 600,
    fontSize: "15px",
    caretColor:"transparent",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  avatar: {
    width:"80px",
    height:"80px",
    borderRadius:"50%"
  },
  content: {
    padding: "10px 26px 20px 32px",
    scrollbarWidth: "thin !important" as ScrollbarType,
    scrollbarColor:"#d9d5d5 #fff !important",
  },
  button: {
    color: "#2196f3",
    border: "2px solid #2196f3",
    margin: "8px 95px 30px",
    padding: "8px 30px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: "14px",
    '@media(max-width:600px)':{
      margin:'20px auto',
      display:'block'
    }
  } as any,
  docList:{
    marginTop:"12px",
    borderBottom:"2px solid #e8e7e7",
  },
  container:{
    display:"flex",
    gap:'24px',
    '@media(max-width:600px)':{
      flexDirection:'column',
      alignItems:'center'
    }
  },
  blockOne:{
    display:"block",
    
  },
  blockTwo:{
    
    display:"block",
    
  },
  blockThree:{
    
    display:"block",
    float:"right",
    textAlign:"right",
    margin: "auto 0px auto 13px"
  } as any,
  userName:{
    fontWeight: 600,
    fontSize: "16px",
    caretColor:"transparent",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  status:{
    caretColor:"transparent",
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color:"gray"
  },
  day:{
    fontWeight: 600,
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color:"#014866",
    caretColor:"transparent",
  },
  time:{
    caretColor:"transparent",
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color:"gray"
  },
  date: {
    width:"75%",
    display:"flex",
    justifyContent:"center",
    fontWeight: 600,
    fontSize: "15px",
    caretColor:"transparent",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color:"#e3e3e3"
  },
  noDatafound:{
    display:"flex",
    fontFamily:" Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 600,
    paddingLeft: "10px",
    justifyContent: "center",
    fontSize: "22px",
    margin: "22px auto 35px",
    caretColor:"transparent",
  },
  closeBtnIcon:{
      padding:"12px 0px 12px 12px !important"
  }
   // Customizable Area End
});

