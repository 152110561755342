import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, createTheme } from "@material-ui/core";
import VideoCallManagementController, { Props } from "./VideoCallManagementController";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SidebarNotificationWrapper from "../../../notifications/src/Notifications.web";
import { videoImg, micIcon, phoneIcon, soundIcon, zoomIcon, refreshIcon } from "../assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class VideoCall extends VideoCallManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box className={classes.videoCallWrapper}>
                    <SidebarNotificationWrapper {...this.props}>
                        <Box className={classes.wrapperContainer}>
                            <Box className={classes.root}>
                                <Box className={classes.headerContainer}>
                                    <Box className={classes.iconWrapper}>
                                        <ArrowBackIosIcon
                                            className={classes.backBtn} />
                                        <Typography variant="h4" className={classes.heading}>Dr. Brijamohan Mallick</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Typography variant="h4" className={classes.timing}>25:14</Typography>
                            <Box className={classes.container}>
                                <Box>
                                    <img src={videoImg} className={classes.callContainer} />
                                </Box>
                                <Box className={classes.btnContainer}>
                                    <img src={micIcon} className={classes.iconSize} />
                                    <img src={soundIcon} className={classes.iconSize} />
                                    <img src={phoneIcon} className={classes.iconSize} />
                                    <img src={refreshIcon} className={classes.iconSize} />
                                    <img src={zoomIcon} className={classes.iconSize} />
                                </Box>
                            </Box>
                        </Box>
                    </SidebarNotificationWrapper>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    videoCallWrapper: {
        height: "100%",
        overflowX: "hidden" as "hidden"
    },
    wrapperContainer: {
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#d9d5d5 #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
        padding: "50px",
        '@media(max-width:600px)': {
            padding: '24px'
        }
    },
    heading: {
        width: "100%",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        caretColor: "transparent",
        '@media(max-width:600px)': {
            fontSize: '18px'
        }
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    iconWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
        marginBottom: "20px"
    },
    backBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "13px 9px 13px 17px",
    },
    btnContainer:{
        display: "flex",
        gap: "25px",
        justifyContent: "center",
        marginTop: "25px",
        maxWidth:"100%",
        '@media(max-width:959px)': {
            maxWidth:"100%",
            width:"100%",
        }
    },
    iconSize:{
        width:"70px",
        height:"70px",
        '@media(max-width:650px)': {
            width:"50px",
            height:"50px",
        }
    },
    callContainer: {
        maxWidth:"100%",
        width:"100%",
        maxHeight: "60%",
        '@media(max-width:959px)': {
            maxWidth:"100%",
            width:"100%",
        }
    },
    timing:{
        fontFamily: "SF Pro Text Regular",
        fontSize:"16px",
        color: "#292929",
        marginBottom: "20px"
    },
    container:{
        width: "75%",
        '@media(max-width:1250px)': {
            width:"100%"
        }
    }
}

export default withStyles(styles)(VideoCall);
// Customizable Area End