import * as React from "react";
// Customizable Area Start
import { withStyles,Grid, ThemeProvider, createTheme } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import Sidebar from "../../../../components/src/Sidebar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

// Customizable Area End

export class PatientPrescriptionView extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.prescriptionContainer}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11} className={classes.prescriptionContainerWrapper}>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  prescriptionContainer: {
    overflowX: "hidden" as "hidden",
    height: "100%"
  },
  prescriptionContainerWrapper: {
    padding: "50px",
    scrollbarWidth: "thin" as "thin",
    overflowY: "scroll" as "scroll",
    scrollbarColor: "#fff #fff",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
  },
}

export default withStyles(styles)(PatientPrescriptionView);
// Customizable Area End