import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, Typography, withStyles } from '@material-ui/core';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import CommonModal from '../../../components/src/CommonModal.web';
import Loader from '../../../components/src/Loader.web';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import { successLogo } from './assets';

export class DeactivateProfile extends CustomisableUserProfilesController {
    async componentDidMount(): Promise<void> {
        this.getDeactivatePageDetails()
    }
    render() {
        const {classes} = this.props;
        return (
            <Box style={webStyle.container}>
                <SidebarNotificationWrapper hideNotification {...this.props} >
                    <Box style={webStyle.rightWrapper}>
                        <HospitalProfileWrapper
                            data-test-id="profile-wrapper"
                            {...this.props}
                            profileBackBtn
                            profilePageTitle={configJSON.PageTitle.DeactivateAccount}
                            hideProfileActionBtns
                        >
                            <Box sx={webStyle.contentWrapper}>
                                <p className={classes.heading}>HomeCure</p>
                                <p className={classes.subHeading}>
                                    We’re sorry to hear you’d like to deactivate your account.
                                </p>
                                <p className={classes.description}>
                                    In compliance with the "Electronic Health Records" standards of 2013 (revised in 2016)
                                    stating that "electronic records must compulsorily be preserved and never destroyed
                                    during the lifetime of the person”, we will store your medical data safely and ensure you
                                    get access to it whenever you wish to reactivating your account and even if you do not
                                    reactivate your account, your account will get deleted after 1 month, but your medical data
                                    will be stored safely with us.
                                </p>
                                <CustomStyledButton
                                    data-test-id='deactivate-button'
                                    style={webStyle.deactivateButton}
                                    onClick={this.openDeactivateConfirmModal}
                                    label="Deactivate Account"
                                />
                            </Box>

                            <CommonModal
                                data-test-id='modal'
                                open={this.state.openDeactivateModal}
                                dialogStyle={webStyle.modalDialog}
                                contentBoxStyle={{paddingBottom:'60px'}}
                                onClose={this.closeDeactivateConfirmModal}
                            >
                               {this.state.modalType === "success"? <Box style={webStyle.deactivationSuccessModal}>
                                    <img src={successLogo} className={classes.successIcon} />
                                    <p className={classes.successModalText}>Your account has been Deactivated successfully</p>
                                    <CustomStyledButton data-test-id='done' onClick={this.closeSuccessDeactivateModal} label="Done" style={{width:'100%'}} />
                                </Box>:
                                <Box style={webStyle.modalContent}>
                                    <Typography style={webStyle.modalHeading}>Deactivate your account?</Typography>
                                    <Typography style={webStyle.modalSubHeading}>Are you sure?</Typography>
                                    <Typography style={{...webStyle.modalSubHeading, fontSize:'12px',marginBottom:'30px',textAlign:'center'}}>clicking <strong>Yes</strong> will log you out of your account.</Typography>
                                    <Box style={webStyle.modalButtonsWrapper}>
                                        <CustomStyledButton
                                            isSecondary
                                            data-test-id='No'
                                            onClick={this.closeDeactivateConfirmModal}
                                            style={webStyle.modalButton}
                                            label={configJSON.ButtonLabel.No}
                                        />
                                        <CustomStyledButton
                                            data-test-id='Yes'
                                            onClick={this.deactivateAccount}
                                            style={webStyle.modalButton}
                                            label={configJSON.ButtonLabel.Yes}
                                        />
                                    </Box>
                                </Box>}
                            </CommonModal>
                            <Loader loading={this.state.isLoading} />
                        </HospitalProfileWrapper>
                    </Box>
                </SidebarNotificationWrapper>
            </Box>
        );
    }
}


const webStyle = {
    deactivationSuccessModal :{
        display:"flex",
        flexDirection:'column' as const, 
        justifyContent:'center',
        alignItems:'center',
        gap:'20px'
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 60px 72px 60px',
        maxWidth: '80%',
        '@media(max-width:991px)':{
            maxWidth:'none',
            padding:'94px 48px 72px 48px'
        },
        '@media(max-width:600px)':{
            padding:'36px 24px 72px 24px'
        }
    },
    heading: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '32px',
        marginTop:'0',
        '@media(max-width:600px)':{
            fontSize:'22px',
            marginBottom:'12px'
        }
    },
    subHeading: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
        lineHeight:1.5,
        marginTop:'0',
        '@media(max-width:600px)':{
            fontSize:'16px',
        }
    },
    description: {
        fontFamily: 'SF Pro Text Regular',
        textAlign: 'justify' as const,
        color: '#555555',
        fontSize: '18px',
        lineHeight: '30px',
        marginBottom: '50px',
        marginTop:0,
        '@media(max-width:600px)':{
            fontSize:'16px',
            marginBottom:'24px',
        }
    },
    deactivateButton: {
        width: '308px',
    },
    modalDialog: {
        borderRadius: '12px',
        backgroundColor: '#F2F1FA',
        maxWidth: '420px',
        width: '100%',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        maxWidth: '263px',
        margin: '0 auto',
    },
    modalHeading: {
        color: '#292929',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: '20px',
    },
    modalSubHeading: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '6px',
    },
    modalButtonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap:"24px"
    },
    modalButton: {
        width: '100px',
        height:'42px'
    },
    successModalText:{
        color: '#292929',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: '20px',
        fontSize:'18px',
        textAlign:'center' as const,
        marginTop:'0',
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    successIcon:{
        width:'120px',
        '@media(max-width:600px)':{
            width:'90px'
        }
    }
};
export default withStyles(webStyle)(DeactivateProfile);
