import React from 'react';
import { Box, Button, Checkbox, Dialog, InputAdornment, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, withStyles } from "@material-ui/core";
import CustomisableUserProfilesController, { configJSON,Props } from './CustomisableUserProfilesController';
import { checked, ratingStar, ProfileIcon, unChecked, NoData, MiscGreen, MiscGray, search_Icon} from './assets';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

export class CustomisableManageHospital extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        if(this.state.manageView === configJSON.ManagePageViewType.bed){
            this.getAllHospitalList();
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }
      
    async componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
      
    
    renderCellOverlay = (dataId: number) => {
        return !this.state.checkedBedRows.includes(`${dataId}`) && (
            <Box style={webStyle.cellOverlay}></Box>
        )
    }

    showErrorPopup = (style: any, message: string, type: string) => {
        return (
            <Box>
                <Dialog open={!!message}>
                    <Box style={style.customContainer}>
                        <Box style={{ padding: "0px 10px" }}>
                            <p style={style.heading}>{type}</p>
                            <p style={style.paragraph}>{message}</p>
                            <Button style={style.doneBtn} 
                            onClick={this.cloeErrorHPPopup}
                            >OK</Button>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        )
    } 

    showAvailableBeds = () => {
        return (
            <>
                {!this.state.isBedAvailable && <Box data-test-id='table-overlay' style={webStyle.tableOverlay}></Box>}
            </>)
    }

    manageAvailableBeds = () => {
        const {classes } = this.props;
        return(
            <Box>
                {this.state.manageView === "bed" && (
                    <Box style={{ display: "flex", gap: "10px",alignItems:'center' }}>
                        <Box>
                            <p className={classes.manageTitle}>Do you have available beds?</p>
                        </Box>
                        <div>
                            {this.state.isBedAvailable ? (
                                <>
                                    <img
                                        src={MiscGreen}
                                        data-test-id='unAvailable'
                                        onClick={this.handleBedManage}
                                        alt="MiscGray"
                                        className={classes.diactivatedBed}
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        src={MiscGray}
                                        data-test-id='isBedAvailable'
                                        onClick={this.handleBedManage}
                                        alt="MiscGray"
                                        className={classes.diactivatedBed}
                                    />
                                </>
                            )}
                        </div>
                    </Box>
                )}
            </Box>
        )
    }

    showHeathcarePersonnel = () => {
        const {classes} = this.props;
        return (
            <>
                {this.state.manageView === configJSON.ManagePageViewType.healthcareperson &&
                    <>
                        {this.state.healthcareData.length > 0 ? (
                            <div style={{display:'flex',flexDirection:'column',gap:'30px'}}>
                                {this.state.healthcareData.map((data: any, index: number) => {
                                    return (
                                        <Box key={index} className={classes.doctorCardWrapper} data-test-id='healthcare-person-view'>
                                            <Box className={classes.doctorCard} >
                                                <Box className={classes.avatarNameWrapper}>
                                                    <img style={webStyle.avatar} src={data.attributes.profile_image_url === null ? ProfileIcon : data.attributes.profile_image_url} />
                                                    <div style={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                                                        <Box style={webStyle.ratingWrapper}>
                                                            <p className={classes.doctorName}>Dr. {data.attributes.name}</p>
                                                            <p className={classes.speciality}>{data.attributes.specialist}</p>
                                                        </Box>
                                                        <Box>
                                                            <p className={classes.rating}> <img src={ratingStar} />4.2 <span style={{ color: '#3780E0' }}> / 5</span></p>
                                                        </Box>
                                                    </div>
                                                </Box>
                                            </Box>
                                            <Button data-test-id={`viewDetailsButton${index}`} style={webStyle.viewDetailsButton} onClick={() => this.showProfile(parseInt(data.id))}>{configJSON.ManagePageButtons.ViewDetails}</Button>
                                        </Box>)
                                })}
                            </div>) : (
                            <Box style={webStyle.noDataContainer}>
                                <img
                                    src={NoData}
                                    width={60}
                                    height={60}
                                    style={webStyle.closeIcon}
                                />
                                <Box style={webStyle.noData}>No results found</Box>
                            </Box>
                        )}
                    </>
                }
            </>
        )
    }

    render() {
        const {classes} = this.props;
        return <div className={classes.container}>
            <SidebarNotificationWrapper {...this.props} >
                <div className={classes.rightWrapper}>
                    <div className={classes.rightSideContentWrapper}>
                        <StyledSearch
                            fullWidth
                            data-test-id="search-doctor"
                            variant="outlined"
                            placeholder={"Search..."}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            src={search_Icon}
                                            alt="search"
                                            style={webStyle.searchIcons}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    borderRadius: "8px",
                                }
                            }}
                        />

                        <div className={classes.switchWrapper}>
                            <p className={classes.manageHeading}>{configJSON.TableTitles.Manage}</p>
                            {this.manageAvailableBeds()}
                        </div>

                        <div style={{ paddingTop: '40px' }}>
                            <div className={classes.tabsWrapper}>
                                <Button data-test-id='bedView' onClick={this.handleOpenBedView} style={this.state.manageView === configJSON.ManagePageViewType.bed ? webStyle.activeTab : webStyle.inActiveTab}>
                                    {configJSON.ManagePageButtons.Beds}
                                </Button>
                                <Button data-test-id='healthcare-view' onClick={this.handleOpenHealthCarePersonView} style={this.state.manageView === configJSON.ManagePageViewType.healthcareperson ? webStyle.activeTab : webStyle.inActiveTab}>
                                    {configJSON.ManagePageButtons.HealthcarePersonnel}
                                </Button>
                            </div>
                            <div style={{ paddingTop: '48px' }}>
                                {this.state.manageView === configJSON.ManagePageViewType.bed && <div className={classes.tableWrapper}>
                                    {this.showAvailableBeds()}
                                    <div className={classes.tableTitleWrapper}>
                                        <p className={classes.tableTitle}>{configJSON.TableTitles.TotalBeds}</p>
                                        <p className={classes.tableTitle}>{this.state.totalBed} Beds</p>
                                    </div>
                                    <TableContainer ref={(node) => (this.tableRef = node)}>
                                        <Table>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: '#EBF9FF' }}>
                                                    <TableCell className={classes.tableHeaderCell} style={{ paddingLeft: '26px' }} >
                                                        {configJSON.TableTitles.Select}
                                                    </TableCell>
                                                    <TableCell className={classes.tableHeaderCell} align='center'>
                                                        {configJSON.TableTitles.Beds}
                                                    </TableCell>
                                                    <TableCell className={classes.tableHeaderCell} align='center'>
                                                        {configJSON.TableTitles.Count}
                                                    </TableCell>
                                                    <TableCell className={classes.tableHeaderCell} align="center">
                                                        {configJSON.TableTitles.Price}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <StyledTableBody>
                                                {this.state.bedData.map((data:any, index: number) => {
                                                    return(
                                                        <TableRow>
                                                            <TableCell style={webStyle.checkboxCellStyle}>
                                                                {this.renderCellOverlay(data.attributes.id)}
                                                                <Checkbox disableRipple data-test-id={`row-checkbox${index}`} checked={this.state.checkedBedRows.includes(`${data.attributes.id}`)} name={`${data.attributes.id}`} onChange={this.handleChangeCheckManageBedRow} checkedIcon={<img src={checked} alt="checked_icon" />} icon={<img src={unChecked} alt="unchecked_icon" />} />
                                                            </TableCell>
                                                            <TableCell style={webStyle.dataCellWrapper}>
                                                                {this.renderCellOverlay(data.attributes.id)}
                                                                <Typography style={{ ...webStyle.cellValue, color: '#292929' }}>{data.attributes.name}</Typography>
                                                            </TableCell>
                                                            <TableCell style={webStyle.dataCellWrapper}>
                                                                {this.renderCellOverlay(data.attributes.id)}
                                                                <Typography 
                                                                    contentEditable={true} 
                                                                    style={webStyle.cellValue}
                                                                    suppressContentEditableWarning={true} 
                                                                    data-test-id={`addCount${index}`}
                                                                    onInput={(e: any) => {
                                                                        let countInput = e.target.innerText;
                                                                        if (!/^\d*$/.test(countInput)) {
                                                                            countInput = countInput.replace(/\D/g, '');
                                                                            e.target.innerText = countInput; 
                                                                        }
                                                                        this.handleInputChange(data.attributes.id, 'count', parseInt(countInput, 10) || 0);
                                                                    }}
                                                                >
                                                                    {data.attributes.count}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={webStyle.dataCellWrapper}>
                                                                {this.renderCellOverlay(data.attributes.id)}
                                                                <Box style={{display:"flex"}}>
                                                                    <Typography
                                                                        style={{...webStyle.cellValue,display:"inline-block",marginRight:"4px"}}
                                                                    >
                                                                        ₹{' '}
                                                                    </Typography>
                                                                    <Typography
                                                                        suppressContentEditableWarning={true}
                                                                        contentEditable={true}
                                                                        style={webStyle.cellValue}
                                                                        data-test-id={`addPrice${index}`}
                                                                        onInput={(e: any) => {
                                                                            let priceInput = e.target.innerText;
                                                                            if (!/^\d*$/.test(priceInput)) {
                                                                                priceInput = priceInput.replace(/\D/g, '');
                                                                                e.target.innerText = priceInput;
                                                                            }
                                                                            this.handleInputChange(data.attributes.id, 'price', parseInt(priceInput, 10) || 0);
                                                                        }}
                                                                    >
                                                                        {data.attributes.price}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </StyledTableBody>
                                        </Table>
                                    </TableContainer>
                                </div>}
                                {this.showHeathcarePersonnel()}
                            </div>
                        </div>
                    </div>
                </div>
            </SidebarNotificationWrapper>
            {this.showErrorPopup(webStyle,this.state.showMsg,this.state.type)}
        </div >
    }
}





const StyledSearch = styled(TextField)({
    
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #014866",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "16px",
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: 'SF Pro Text Regular',
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '10px'
    }
})

const StyledTableBody = styled(TableBody)({
    '&.MuiTableBody-root':{
        minWidth:"767px",
        overflow:'auto'
    }
})


const webStyle = {
    checkboxCellStyle: {
        padding: '0 16px 0 40px',
        position: 'relative' as const 
    },
    dataCellWrapper: {
        padding: '0 16px',
        height: '45px',
        position: 'relative' as const,
    },
    cellOverlay: {
        position: "absolute" as const,
        backgroundColor: 'white',
        opacity: '0.5',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    doctorCardWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '50px',
        flexWrap:'wrap' as const,
        '@media(max-width:991px)':{
            flexWrap:'nowrap' as const,
        },
        '@media(max-width:660px)':{
            flexDirection:'column',
        }
    },
    manageTitle: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        color:"#292929",
        margin:'0',
        '@media(max-width:600px)':{
            fontSize:'14px'
        }
    },
    manageHeading:{
        fontFamily: 'SF Pro Text Bold',
        fontSize: '28px',
        margin:'0',
        color:"#000000",
        '@media(max-width:991px)':{
            fontSize:'24px'
        },
        '@media(max-width:600px)':{
            fontSize:'22px'
        }
    },
    cellValue: {
        color: '#3780E0',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Regular',
        textAlign: 'center' as const,
        whiteSpace: 'nowrap' as const,
        overflow: 'hidden',
        textOverflow: "ellipsis"
    },
    viewDetailsButton: {
        color: 'white',
        backgroundColor: '#014866',
        maxWidth: "170px",
        width: '100%',
        textTransform: 'capitalize' as const,
        borderRadius: '8px',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        height: '52px'
    },
    tabsWrapper: {
        display: 'flex',
        padding: '11px 20px',
        alignItems: 'center',
        boxSizing: 'border-box' as 'border-box',
        maxWidth: '360px',
        width: '100%',
        border: '1px solid #808080',
        borderRadius: '8px',
        justifyContent: 'space-between'
    },
    switchWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '40px',
        gap:'15px',
        flexWrap:'wrap' as const
    },
    tableTitleWrapper: {
        padding: '30px 40px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableWrapper: {
        position: "relative" as const,
        boxShadow: "0px 2px 24px 0px #B3B3B31F",
        border: 'border: solid #5F5F5F09',
        borderRadius: '25px',
        overflow: 'hidden',
        boxSizing: 'border-box' as const,
    },
    tableOverlay: {
        position: 'absolute' as const,
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 10,
        opacity: '0.5',
        cursor: "not-allowed"
    },
    tableTitle: {
        color: '#6C6C6C',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Bold',
        margin:'0'
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%'
    },
    sidebarWrapper: {
        width: '132px'
    },
    rightSideContentWrapper: {
        padding: '46px 60px',
        display: 'flex',
        flexDirection: 'column' as const,
        width:'100%',
        boxSizing:'border-box' as const,
        '@media(max-width:600px)':{
            padding:'24px'
        }
    },
    searchIcons: {
        width: "24px",
        objectFit: "cover" as const,
        height: "24px",
        display: "flex",
        justifyContent: "center",
        margin: "0px auto"
    },
    activeTab: {
        textTransform: 'capitalize' as const,
        backgroundColor: '#3780E0',
        color: 'white',
        borderRadius: '8px',
        fontSize: '18px',
        padding: "10px 25px",
        lineHeight: '22px',
        fontFamily:'SF Pro Text Semibold',
        caretColor:"transparent"
    },
    inActiveTab: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        padding: '10px 0px',
        fontSize: '18px',
        lineHeight: '22px',
        textTransform: 'capitalize' as const,
        caretColor:"transparent"
    },
    tableHeaderCell: {
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Semibold',
        borderBottom: 'none',
        '@media(max-width:600px)':{
            fontSize:'16px'
        }
    },
    doctorCard: {
        display: 'flex',
        alignItems: 'center',
        padding: '18px 32px',
        boxSizing:'border-box' as const,
        borderRadius: '8px',
        justifyContent: 'space-between',
        border: '1px solid #80808021',
        boxShadow: "0px 2px 40px 0px #B3B3B31F",
        maxWidth: '540px',
        width: '100%',
        '@media(max-width:660px)':{
            maxWidth:"300px"
        }
    },
    avatarNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '20px',
        width:'100%',
        '@media(max-width:660px)':{
            flexDirection:'column',
        }
    },
    avatar: {
        width: '85px',
        caretColor:"transparent",
        height: '85px'
    },
    ratingWrapper: {
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    doctorName: {
        fontSize: '20px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: "8px",
        marginTop:'0',
        '@media(max-width:600px)':{
            fontSize:'18px'
        }
    },
    speciality: {
        color: '#AC8E68',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Regular',
        margin:'0'
    },
    rating: {
        color: '#014866',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Medium',
        textAlign: 'end' as const ,
        marginBottom: "8px",
        marginTop:'0',
        whiteSpace:'nowrap' as const
    },
    notificationWrapper: {
        minWidth: '340px'
    },
    noDataContainer:{
      width:"362px",
      display:"block",
      margin:"50px auto"
    },
    closeIcon:{
      display:"flex",
      margin:"auto"
    },
    noData:{
      fontSize:"20px",
      fontFamily:"SF Pro Text Medium",
      color:"#808080", 
      width:"100%",
      display:"flex",
      justifyContent:"center",
      margin:"60px auto"
    },
    customContainer: {
        display: "block",
        maxWidth: "550px",
        width: "500px",
        height: "auto",
        textAlign: "left" as "left",
        borderRadius: "5px"
      },
      heading: {
        width: "100%",
        color: "black",
        fontFamily: "SF Pro Text Regular",
        fontSize: "28px",
        fontWeight: 700,
        height: "8px",
        marginTop: "15px",
        caretColor: "white"
      },
      paragraph: {
        width: "100%",
        color: "black",
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        height: "8px",
        margin: "30px 0px",
        caretColor: "white"
      },
      doneBtn:{
        borderRadius: "3px",
        backgroundColor: "rgb(33, 150, 243)",
        border: "none",
        color: "#fff",
        padding: "5px",
        marginBottom: "15px",
        marginTop: "10px",
        fontFamily: "SF Pro Text Regular"
    },
    diactivatedBed: {
        display: "flex",
        justifyContent: "center",
        height: "30px",
        margin: "0px auto",
        cursor: "pointer",
        objectFit: "cover" as "cover",
        '@media(max-width:600px)':{
            height:'20px'
        }
      },
}

export default withStyles(webStyle)(CustomisableManageHospital);